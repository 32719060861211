import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import LoaderComponent from "../LoaderComponent";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

export default function TotalUsersChart({ graphData, graphLoading }) {
  const brandDefaultData = {
    label: "Brand",
    borderColor: "#FF66CC",
    backgroundColor: "transparent",
    borderWidth: 2,
    pointRadius: 0,
    pointHoverRadius: 0,
    tension: 0.4,
  };
  const creatorDefaultData = {
    label: "Creator",
    borderColor: "#6699FF",
    backgroundColor: "transparent",
    borderWidth: 2,
    pointRadius: 0,
    pointHoverRadius: 0,
    tension: 0.4,
  };

  const data = {
    labels: graphData?.labels,
    datasets: [
      {
        ...brandDefaultData,
        data: graphData?.datasets?.brands,
      },
      {
        ...creatorDefaultData,
        data: graphData?.datasets?.creators,
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => `Aug 2024`,
          label: (context) => {
            if (context.datasetIndex === 0) return `Brand: ${context.raw}`;
            if (context.datasetIndex === 1) return `Creator: ${context.raw}`;
          },
          footer: (context) => `Total: ${context[0].raw + context[1].raw}`,
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: "#A4ACBA",
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          color: "#EFF3F7",
        },
        ticks: {
          color: "#A4ACBA",
          font: {
            size: 10,
          },
        },
      },
    },
  };

  return (
    <div className="total-users-chart-wrapper">
      {graphLoading ? (
        <LoaderComponent />
      ) : (
        <Line data={data} options={options} />
      )}
    </div>
  );
}
