import React from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";
import { Link } from "react-router-dom";

export default function PreferencesActivityLog() {
    return (
        <div className="preferences-activity-log-content-wrapper">
            <div className="section-title-with-cards d-block">
                <div className="row">
                    <div className="col-md-5 align-self-center">
                        <strong className="semi_bold dark large">Activity Log</strong>
                    </div>
                    <div className="col-md-7 align-self-center">
                        <div className="right-menu-wrapper cusrtom-search-bar-wrapper">
                            <div className="search-bar-wrapper">
                                <img
                                    src={SearchIcon}
                                    alt="SearchIcon"
                                    className="search-icon"
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Logs"
                                />
                            </div>
                            <div className="filter-and-view-wrapper">
                                <div className="sort-by-wrapper">
                                    <strong>Filter: </strong>
                                    <DropdownButton
                                        id={`dropdown-button-drop-down`}
                                        drop={"down"}
                                        variant="secondary"
                                        title={'Newest'}
                                    >
                                        <Dropdown.Item eventKey="createdAt:desc">
                                            All
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="createdAt:asc">
                                            Oldest First
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="jobTitle:asc">A-Z</Dropdown.Item>
                                        <Dropdown.Item eventKey="jobTitle:desc">Z-A</Dropdown.Item>
                                    </DropdownButton>
                                    {/* <img src={filterIcon} alt="filterIcon" className="filter-icon" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-wrapper">
                <table className="simple no-border">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <strong><span>Applied for a job</span> Looking for Top GMV creators </strong>
                            </td>
                            <td><strong>09/22/2024</strong></td>
                            <td>
                                <DropdownButton
                                    id={`dropdown-button-drop-down`}
                                    drop={"down"}
                                    variant="secondary"
                                    className="custom-dropdown three-dots-line"
                                    title={<PiDotsThreeOutlineVerticalFill className='action-icon' />}
                                >
                                    <div className="title">
                                        <strong className="dark">Action</strong>
                                    </div>
                                    <Dropdown.Item
                                        eventKey="1"
                                        as={Link}
                                    >
                                        View details
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey="2"
                                        as={Link}
                                    >
                                        Delete
                                    </Dropdown.Item>
                                </DropdownButton>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <strong><span>Applied for a job</span> Looking for Top GMV creators </strong>
                            </td>
                            <td><strong>09/22/2024</strong></td>
                            <td>
                                <DropdownButton
                                    id={`dropdown-button-drop-down`}
                                    drop={"down"}
                                    variant="secondary"
                                    className="custom-dropdown three-dots-line"
                                    title={<PiDotsThreeOutlineVerticalFill className='action-icon' />}
                                >
                                    <div className="title">
                                        <strong className="dark">Action</strong>
                                    </div>
                                    <Dropdown.Item
                                        eventKey="1"
                                        as={Link}
                                    >
                                        View details
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey="2"
                                        as={Link}
                                    >
                                        Delete
                                    </Dropdown.Item>
                                </DropdownButton>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <strong><span>Applied for a job</span> Looking for Top GMV creators </strong>
                            </td>
                            <td><strong>09/22/2024</strong></td>
                            <td>
                                <DropdownButton
                                    id={`dropdown-button-drop-down`}
                                    drop={"down"}
                                    variant="secondary"
                                    className="custom-dropdown three-dots-line"
                                    title={<PiDotsThreeOutlineVerticalFill className='action-icon' />}
                                >
                                    <div className="title">
                                        <strong className="dark">Action</strong>
                                    </div>
                                    <Dropdown.Item
                                        eventKey="1"
                                        as={Link}
                                    >
                                        View details
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey="2"
                                        as={Link}
                                    >
                                        Delete
                                    </Dropdown.Item>
                                </DropdownButton>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}