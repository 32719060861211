import React from "react";
import { Link } from "react-router-dom";

export default function CreatorProfileOverViewTab({ creator }) {
  const userDetails = creator?.userDetails;
  return (
    <div className="profile-over-view-main-wrapper">
      <div className="creator-basic-info-wrapper">
        <div className="section-title-with-cards">
          <strong className="semi_bold dark large">Basic information</strong>
        </div>
        <div className="basic-information">
          <div className="list-wrapper">
            <p className="medium medium-bold">First name</p>
            <strong className="medium semi_bold dark">
              {creator?.firstName || userDetails?.firstName}
            </strong>
          </div>
          <div className="list-wrapper">
            <p className="medium medium-bold">Last name </p>
            <strong className="medium semi_bold dark">
              {creator?.lastName || userDetails?.lastName}
            </strong>
          </div>
          <div className="list-wrapper">
            <p className="medium medium-bold">Email </p>
            <strong className="medium semi_bold dark">
              <Link to={`mailto:${creator?.email}`}>{creator?.email}</Link>{" "}
            </strong>
          </div>
          <div className="list-wrapper">
            <p className="medium medium-bold">Phone </p>
            <strong className="medium semi_bold dark">{creator?.phone}</strong>
          </div>
          <div className="list-wrapper">
            <p className="medium medium-bold">TikTok Shop Username </p>
            <strong className="medium semi_bold dark">{creator?.tikTokUserName}</strong>
          </div>
          <div className="list-wrapper">
            <p className="medium medium-bold">UGC rate </p>
            <strong className="medium semi_bold dark">{creator?.ugc_price}</strong>
          </div>
        </div>
      </div>
    </div>
  );
}
