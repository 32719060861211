import JobsImg from "../../assets/images/job-img-1.svg";
import eye from "../../assets/images/icons/eye.svg";
import eyeIcon from "../../assets/images/icons/eye-icon.svg";
import shopIcon from "../../assets/images/icons/shop-icon.svg";
import targetIcon from "../../assets/images/icons/target-icon.svg";
import { Link } from "react-router-dom";
import avatarIcon from "../../assets/images/icons/avatar-icon.svg";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { getId } from "../../utils/utils";
import { dateFormat } from "../../utils/format";

const BrandsRowComponent = ({ item }) => {
  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  return (
    <tr>
      <td>
        <div className="main-wrapper user-info">
          <div className="img-wrapper">
            <Link to={`/dashboard/brand-detail/${getId(item)}`}>
              <img
                src={
                  item?.avatar ? parseImagePathToURL(item?.avatar) : avatarIcon
                }
                alt="avatarIcon"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong className="dark semi_bold">
              <Link to={`/dashboard/brand-detail/${getId(item)}`}>
                {item?.name}
              </Link>
            </strong>
            <span className="username">{item?.email}</span>
          </div>
        </div>
      </td>
      <td>
        <p className="dark">
          <Link to={item?.website} target="_blank">
            {item?.website}
          </Link>
        </p>
      </td>
      <td>
        <p className="dark">{item?.jobCount}</p>{" "}
      </td>
      <td>
        <p className="dark">{dateFormat(item?.createdAt)}</p>{" "}
      </td>
      <td>
        <p
          className={`dark status-text ${
            item?.status === "active"
              ? "active"
              : item?.status === "inactive"
              ? "inactive"
              : "pending"
          }`}
        >
          {capitalizeFirstLetter(item?.status) || "Pending"}
        </p>
      </td>
      <td>
        <div className="action-btn-wrapper">
          <Link to={`/dashboard/job-detail`}>
            <div className="icon-wrapper rounded medium">
              <img src={eyeIcon} alt="eyeIcon" />
            </div>
          </Link>
          <DropdownButton
            id={`dropdown-button-drop-down`}
            drop={"down"}
            variant="secondary"
            className="custom-dropdown three-dots-line"
            title={<PiDotsThreeOutlineVerticalFill />}
          >
            <div className="title">
              <strong className="dark">Action</strong>
            </div>
            <Dropdown.Item eventKey="1" as={Link}>
              <img src={eye} alt="eye" /> View job details
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" as={Link} href="">
              <img src={targetIcon} alt="targetIcon" /> Track proposal
            </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <img src={shopIcon} alt="shopIcon" /> View brand profile
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </td>
    </tr>
  );
};

export default BrandsRowComponent;
