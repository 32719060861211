import React from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";

import pointerIcon from '../assets/images/icons/pointer-icon.svg'
import basketIcon from '../assets/images/icons/basket-icon.svg'

export default function SettingAuditLogComponent({ editRoleModalOpenHandler }) {
    return (
        <div className="setting-audit-log-tab-wrapper">
            <div className="tabs-and-filter-bar-main-wrapper">
                <div className="row">
                    <div className="col-md-9 align-self-center">
                        <div className="right-menu-wrapper cusrtom-search-bar-wrapper">
                            <div className="search-bar-wrapper">
                                <img
                                    src={SearchIcon}
                                    alt="SearchIcon"
                                    className="search-icon"
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Jobs"
                                />
                            </div>
                            <DropdownButton
                                id={`dropdown-button-drop-down `}
                                drop={"down"}
                                variant="secondary"
                                className="custom-dropdown"
                                title={'All'}
                            >
                                <Dropdown.Item eventKey="createdAt:desc">
                                    Newest First
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="createdAt:asc">
                                    Oldest First
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="jobTitle:asc">A-Z</Dropdown.Item>
                                <Dropdown.Item eventKey="jobTitle:desc">Z-A</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-wrapper">
                <table className="simple-light-table simple">
                    <thead>
                        <tr>
                            <th><span>Member</span></th>
                            <th><span>Event</span></th>
                            <th><span>Date</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="main-wrapper user-info d-flex">
                                    <div className="text-wrapper">
                                        <strong className="dark semi_bold d-block">Victoria Samuel</strong>
                                        <span>lionelmessi23@gmail.com</span>
                                    </div>
                                </div>
                            </td>
                            <td><p className="light">Reviewed job post Posted a job seeking exceptional GMV creators.</p></td>
                            <td>
                                <div className="main-wrapper user-info d-flex" style={{minWidth:100}}>
                                    <div className="text-wrapper">
                                        <strong className="dark semi_bold d-block" style={{fontWeight: 500}}>Aug 10, 2024</strong>
                                        <span style={{fontWeight: 500}}>08:32 am</span>
                                    </div> 
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}