import React, { useState } from "react";
import TrendLineChart from "./charts/TrendLineChart";
import DashboardTrendsChartComponent from "./DashboardTrendsChartComponent";

export default function TrendChartComponent() {

    const [trendActiveState, setTrendActiveState] = useState('gmv');

    const trendActiveStateHandler = (tab) => {
        setTrendActiveState(tab)
    }


    return (
        <div className="trend-chart-component">
            <div className="section-title-with-cards">
                <strong className="dark large">Trend</strong>
            </div>
            <DashboardTrendsChartComponent trendActiveStateHandler={trendActiveStateHandler} trendActiveState={trendActiveState} />
            {trendActiveState === 'gmv' && <TrendLineChart />}
            {trendActiveState === 'itemSold' && <TrendLineChart />}
            {trendActiveState === 'videoGpm' && <TrendLineChart />}
            {trendActiveState === 'liveViews' && <TrendLineChart />}
            {trendActiveState === 'videoViews' && <TrendLineChart />}
            {trendActiveState === 'liveGpm' && <TrendLineChart />}
        </div>
    )
}