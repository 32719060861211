import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { toast } from "react-toastify";

// user Login With Credentials
export const userLoginAsyncThunk = createAsyncThunk(
  "auth/userLoginAsyncThunk",
  catchAsync(async ({ email, password, router }, { dispatch }) => {
    const response = await ApiRequests.login({
      email,
      password,
      role: "admin",
    });
    if (response) {
      if (response?.status === 200) {
        toast.success("Success! You’re now securely logged in. Welcome back!", {
          autoClose: 2000,
        });
        router("/dashboard");
      } else {
        router("/sign-in");
        toast.error(response.error);
      }
    }
    return response?.data;
  })
);
// user Login With Credentials
export const authenticateAsyncThunk = createAsyncThunk(
  "auth/authenticateAsyncThunk",
  catchAsync(async (__, _) => {
    const token = localStorage.getItem("access-token");
    if (!token) {
      return null;
    }
    const response = await ApiRequests.authenticate();
    return response?.data;
  })
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    // const state = getState();
    const response = await ApiRequests.updateProfile(data);
    if (response?.data?.user) {
      dispatch(storeUser(response?.data?.user));
      toast.success("Your information has been updated successfully");
    }
    if (response.status !== 200) {
      toast.error(response.data.message);
      return;
    }
    if (callBack) callBack();

    return response?.data;
  })
);
export const userLogoutAsyncThunk = createAsyncThunk(
  "auth/userLogoutAsyncThunk",
  catchAsync(async (_, { router }) => {
    const refreshToken = localStorage.getItem("refresh-token");
    const response = await ApiRequests.logout({ refreshToken });
    if (response) {
      if (response?.status === 204) {
        console.log("this is the response");
        localStorage.removeItem("access-token");
        localStorage.removeItem("refresh-token");
        localStorage.removeItem("user");
        toast.success("LogOut Successfully!!!", {
          autoClose: 2000,
        });
        // router("/login")
      } else {
        toast.error(response.error);
      }
      window.location.href = window.location.origin + "/sign-in";
    }
    return true;
  })
);

// user register With Credentials
export const userForgetPasswordAsyncThunk = createAsyncThunk(
  "auth/userForgetPasswordAsyncThunk",
  catchAsync(async ({ email }) => {
    const response = await ApiRequests.forgotpassword({ email });
    if (response) {
      if (response?.status == 201 || response?.status == 200) {
        toast.success("Reset password link sent successfully!", {
          autoClose: 2000,
        });
      } else {
        toast.error(response.error);
      }
    }
    return response?.data;
  })
);

// user register With Credentials
export const userResetPasswordAsyncThunk = createAsyncThunk(
  "auth/userForgetPasswordAsyncThunk",
  catchAsync(async ({ password, token }) => {
    const response = await ApiRequests.resetPassword({ password, token });
    if (response) {
      if (response?.status == 204 || response?.status == 200) {
        toast.success("Password updated successfully!", {
          autoClose: 2000,
        });
      } else {
        toast.error(response.error);
      }
    }
    return response?.data;
  })
);

// user register With Credentials
export const userUpdatePasswordAsyncThunk = createAsyncThunk(
  "auth/userUpdatePasswordAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    const response = await ApiRequests.updatePassword(data);
    if (response) {
      if (response?.status == 204 || response?.status == 200) {
        toast.success("Password updated successfully!", {
          autoClose: 2000,
        });
        if (callBack) callBack();
      } else {
        toast.error(response.error);
      }
    }
    return response?.data;
  })
);
// user register With Credentials
export const updateCreatorProfileAsyncThunk = createAsyncThunk(
  "creator/updateCreatorProfileAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.updateCreatorProfile(data);
    if (response.status === 204) {
      toast.success("Creator Updated Successfully!");
    }
    if (callBack) callBack();
    // dispatch(getCreatorsByIdsAsyncThunk({ populate: "image,creator_id", ...state.creators?.paramsForThunk?.getCreatorsByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);
const initialState = {
  //news states
  user: null,
  tokens: null,
  profile: null,
  brand: null,
  isScreenLock: false,
  creator: null,
  // manager states
  errors: {},
  loadings: {
    authenticateAsyncThunk: true,
    userUpdatePasswordAsyncThunk: false,
  },
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const blogSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeUser: (state, action) => {
      state.user = action.payload;
    },
    storeProfile: (state, action) => {
      state.profile = action.payload;
    },
    setCreator: (state, action) => {
      state.creator = action.payload;
    },
    setBrand: (state, action) => {
      state.brand = action.payload;
      state.profile = action.payload;
      localStorage.setItem("space", JSON.stringify(action.payload));
    },
    setBrandProfile: (state, action) => {
      state.brand = action.payload;
      localStorage.setItem("space", JSON.stringify(action.payload));
    },
    setScreenLock: (state, action) => {
      state.isScreenLock = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload?.user.user;
        state.tokens = action.payload?.user?.session;
        localStorage.setItem(
          "access-token",
          action.payload?.tokens?.access?.token
        );
        localStorage.setItem(
          "refresh-token",
          action.payload?.tokens?.refresh?.token
        );
        localStorage.setItem("user", JSON.stringify(action.payload?.user));
      })
      .addCase(userLogoutAsyncThunk.fulfilled, (state, action) => {
        state.user = null;
        localStorage.setItem("access-token", null);
        localStorage.setItem("refresh-token", null);
        localStorage.setItem("user", null);
      })
      .addCase(authenticateAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload?.user;
        state.brand = action.payload?.brand;
        state.creator = action.payload?.creator;
        state.profile = action.payload?.creator ?? action.payload?.brand;
        localStorage.setItem("space", JSON.stringify(action.payload?.brand));
      })
      .addCase(updateCreatorProfileAsyncThunk.fulfilled, (state, action) => {
        state.creator = action.payload;
        state.logScreen = true;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            userLoginAsyncThunk,
            // refreshTokensAsyncThunk,
            authenticateAsyncThunk,
            userLogoutAsyncThunk,
            updateCreatorProfileAsyncThunk,
            updateProfile,
            userUpdatePasswordAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default blogSlice.reducer;
export const {
  storeUser,
  storeProfile,
  setCreator,
  setBrandProfile,
  setBrand,
  setScreenLock,
} = blogSlice.actions;
