import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsThreeDots } from "react-icons/bs";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import jobFileIcon from "../assets/images/icons/order-file-icon.svg";
import TitleAndFilterBar from "../components/TitleAndFilterBar";
import { parseImagePathToURL } from "../utils/globalFunctions";

import JobsImg from "../assets/images/job-img-1.svg";
import eye from "../assets/images/icons/eye.svg";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import targetIcon from "../assets/images/icons/target-icon.svg";
import ViewProfileDetailModal from "../components/modals/ViewProfileDetailModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobAsyncThunk,
  updateJobAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import {
  getJobApplicantsAsyncThunk,
  getJobApplicationsAsyncThunk,
  getJobTasksAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { dateFormat } from "../utils/format";
import { getId, returnArray, setValueIfExist } from "../utils/utils";
import { toTitleCase } from "../utils/methods";
import {
  confirmPaymentAsyncThunk,
  disputePaymentAsyncThunk,
} from "../redux/pagesSlices/paymentSlice";
import { Spinner } from "react-bootstrap";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import CreatorApplicationCardComponent from "../components/jobs/CreatorApplicationCardComponent";
import NoCreatorFoundComponent from "../components/creators/NoCreatorFoundComponent";
import JobTasksCardComponent from "../components/jobs/JobTasksCardComponent";
import DisputeModal from "../components/modals/DisputeModal";
import ViewJobDetailCenteredModal from "../components/modals/ViewJobDetailCenteredModal";

export default function JobDetailPage() {
  const { jobId } = useParams();
  const { job } = useSelector((state) => state.job);
  const [showDetailModel, setShowDetailModel] = useState(false);
  const [selectedItemId,setSelectedItemId] = useState(null);
//   console.log("selectedItemId", selectedItemId);
  
  const { jobTasks } = useSelector((state) => state.application);
  const [viewJobDetailCenteredModal, setViewJobDetailCenteredModal] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
//   console.log("jobTasks ", job);

  const handleViewJobCenteredModal = () => {
    setViewJobDetailCenteredModal(true);
  }

  const handleViewJobCenteredModalClose = () => {
    setViewJobDetailCenteredModal(false);
  }

  useEffect(() => {
    if (jobId) {
      dispatch(getJobAsyncThunk(jobId));
    }
  }, [jobId]);

  const jobTasksParams = setValueIfExist({
    populate: "creatorId,jobId,applicationId",
    search,
  });

  useEffect(() => {
    if (jobId) {
      dispatch(
        getJobTasksAsyncThunk({
          id: jobId,
          params: jobTasksParams,
        })
      );
    }
  }, [jobId, search]);

  const handleCloseJob = () => {
    dispatch(
      updateJobAsyncThunk({
        id: jobId,
        data: {
          status: "closed",
        },
      })
    );
  };


  return (
    <div className="job-detail-page">
      <div className="inner-page-wrapper active m-0">
        <div className="container">
          <div className="detail-page-main-title-wrapper">
            <div className="inner-wrapper">
              <div className="upper-section-wrapper">
                <div className="row">
                  <div className="col-md-7 align-self-center">
                    <div className="content-wrapper">
                      <div className="back-btn-wrapper">
                        <Link to="/dashboard/jobs">
                          <MdOutlineKeyboardArrowLeft />
                          <strong className="medium-bold">Back to jobs</strong>
                        </Link>
                      </div>
                      <div className="main-title-wrapper">
                        <h3>{job?.jobTitle}</h3>
                        <div className="main-wrapper">
                          <div className="title">
                            <p>
                              Posted: <span>{dateFormat(job?.createdAt)}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 align-self-center">
                    <div className="btn-wrapper">
                      <a
                        href="#"
                        className="btn-style blue-border"
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewJobCenteredModal();
                        }}
                      >
                        <img src={jobFileIcon} alt="jobFileIcon" /> View job
                        details
                      </a>
                      <Dropdown className="close-job-dropdown">
                        <DropdownToggle>
                          <div className="icon-wrapper">
                            <a
                              href="#"
                              className="d-flex align-items-center justify-content-center"
                              style={{ width: "100%", height: "100%" }}
                            >
                              <BsThreeDots />
                            </a>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={handleCloseJob}>Close job</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="job-detail-section">
            <TitleAndFilterBar search={search} setSearch={setSearch} />
            <div className="listings-section">
              <div className="table-wrapper">
                <table className="gapped">
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <span className="white">Name</span>
                      </th>
                      <th>
                        {" "}
                        <span className="white">Videos</span>
                      </th>
                      <th>
                        {" "}
                        <span className="white">Lives</span>
                      </th>
                      <th>
                        {" "}
                        <span className="white">Date applied</span>{" "}
                      </th>
                      <th>
                        {" "}
                        <span className="white">Status</span>{" "}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ProviderErrorLoadingPagination
                      reducer="application"
                      action="getJobTasksAsyncThunk"
                      asyncThunk={getJobTasksAsyncThunk}
                      dataKey="jobTasks"
                      Component={JobTasksCardComponent}
                      emptyMessage={
                        <>
                          <NoCreatorFoundComponent />
                        </>
                      }
                      componentProps={{ jobTasksParams, jobId,setShowDetailModel,showDetailModel,selectedItemId, setSelectedItemId }}
                      pagination
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDetailModel && (
        <ViewProfileDetailModal
          showDetailModel={showDetailModel}
          handleHideDetailModel={() => setShowDetailModel(false)}
        selectedItemId={selectedItemId}
        />
      )}
      <ViewJobDetailCenteredModal viewJobDetailCenteredModal={viewJobDetailCenteredModal} handleViewJobCenteredModalClose={handleViewJobCenteredModalClose} item={job}/>
    </div>
  );
}
