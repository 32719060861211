import { memo, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import profileImg from "../../assets/images/icons/avatar-icon.svg";
import proposalImg from "../../assets/images/proposal-img.svg";
import countryIcon from "../../assets/images/icons/map-icon.svg";
import dollarSign from "../../assets/images/icons/dollar-sign.svg";
import regroupIcon from "../../assets/images/icons/regroup-icon.svg";
import profileBagIcon from "../../assets/images/icons/shopping-bag-icon.svg";
import message from "../../assets/images/icons/sms-icon.svg";
import { CiHeart } from "react-icons/ci";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleJobTaskAsyncThunk,
  updateApplicationAsyncThunk,
} from "../../redux/pagesSlices/applicationSlice";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { getId, returnArray } from "../../utils/utils";
import { useFormik } from "formik";
import { Spinner } from "react-bootstrap";
import { RiSendPlane2Fill } from "react-icons/ri";
import * as Yup from "yup";

function ViewProfileDetailModal({
  showDetailModel,
  handleHideDetailModel,
  selectedItemId,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    let params = {};
    dispatch(
      getSingleJobTaskAsyncThunk({
        id: selectedItemId,
        params,
      })
    );
  }, [dispatch, selectedItemId]);

  const jobTask = useSelector((state) => state?.application?.jobTask);

  const creator = jobTask?.creatorId;
  const application = jobTask?.applicationId;
  const { loadings } = useSelector((s) => s.application);

  const offeredVideos = returnArray(application?.offeredVideoPrice);
  const offeredLivePrice = returnArray(application?.offeredLivePrice);

  const formik = useFormik({
    initialValues: {
      brandNote: application?.brandNote || "",
    },
    validationSchema: Yup.object({
      brandNote: Yup.string()
        .required("This field is required")
        .min(3, "Must be at least 3 characters")
        .max(150, "Must be at most 150 characters"),
    }),
    onSubmit: (values) => {
      dispatch(
        updateApplicationAsyncThunk({
          id: getId(jobTask?.applicationId),
          data: values,
        })
      );
    },
  });

  return (
    <div className="container">
      <Modal
        show={showDetailModel}
        onHide={handleHideDetailModel}
        className="view-profile-short-info-modal"
        centered
      >
        <Modal.Header>
          <Button
            variant="close"
            onClick={handleHideDetailModel}
            aria-label="Close"
            style={{ marginLeft: 0 }}
            className="cancel-btn "
          ></Button>
          <Link
            className="m-0 blue"
            to={`/dashboard/creator-detail/${getId(creator)}`}
          >
            View full Profile
          </Link>
        </Modal.Header>

        <Modal.Body>
          <div className="top-profile-box">
            <div className="profile-info">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="profile-left">
                    {creator?.avatar ? (
                      <img
                        src={parseImagePathToURL(creator?.avatar)}
                        alt="avatar"
                        className="profile-img"
                      />
                    ) : (
                      <img src={profileImg} alt="" className="profile-img" />
                    )}

                    <div className="profile-text">
                      <h5>{creator?.name || "Creator Name"} </h5>
                      <p>@{creator?.tikTokUserName || "username"}</p>
                      <div className="profile-icons">
                        <div className="profile-text-icon">
                          <img src={countryIcon} alt="" />
                          <p>{creator?.location || "Location"}</p>
                        </div>
                        <div className="profile-text-icon">
                          <img src={profileBagIcon} alt="" />
                          <p>48 jobs</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6">
                  <div className="profile-btn">
                    <button>
                      <img src={message} alt="" style={{ color: "white" }} />
                      Message Creator
                    </button>
                    <div className="heart-icon">
                      <CiHeart fontSize={25} />
                    </div>
                    <div className="three-dot-icon">
                      <BsThreeDots fontSize={20} />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="top-profile-bottom-text">
              <div className="profile-bottom-text">
                <p>Estimated ROI</p>
                <span>3.5X</span>
              </div>
              <div className="profile-bottom-text">
                <p>GMV (last 28 days)</p>
                <span>$500</span>
              </div>
              <div className="profile-bottom-text">
                <p>GMV per video</p>
                <span>$900</span>
              </div>
            </div>
          </div>
          <div className="profile-center-box">
            <div className="main-center-profile">
              <div className="center-text">
                <h1>Proposal details</h1>
                <h6>What makes you unique for this job?</h6>
                <p>{creator?.bio}</p>
              </div>
            </div>

            <div className="image-container">
              {application?.isHired ? (
                <>
                  <strong className="dark semi_bold">Recent Works</strong>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="image-side-text-parent">
                        <img src={proposalImg} alt="png img" />
                        <div className="text-icon-parent">
                          <div className="text-icon">
                            <div className="inner-text-icon">
                              <img src={regroupIcon} alt="Regrouplight icon" />
                              <span className="roi-text">ROI</span>
                            </div>
                            <span className="text-3x">3x</span>
                          </div>
                          <div className="text-icon">
                            <div className="inner-text-icon">
                              <img src={dollarSign} alt="dollarSign" />
                              <span className="roi-text">GMV</span>
                            </div>
                            <span className="text-3x">$89</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="image-side-text-parent">
                        <img src={proposalImg} alt="png img" />
                        <div className="text-icon-parent">
                          <div className="text-icon">
                            <div className="inner-text-icon">
                              <img src={regroupIcon} alt="Regrouplight icon" />
                              <span className="roi-text">ROI</span>
                            </div>
                            <span className="text-3x">3x</span>
                          </div>
                          <div className="text-icon">
                            <div className="inner-text-icon">
                              <img src={dollarSign} alt="dollarSign" />
                              <span className="roi-text">GMV</span>
                            </div>
                            <span className="text-3x">$89</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="image-side-text-parent">
                        <img src={proposalImg} alt="png img" />
                        <div className="text-icon-parent">
                          <div className="text-icon">
                            <div className="inner-text-icon">
                              <img src={regroupIcon} alt="Regrouplight icon" />
                              <span className="roi-text">ROI</span>
                            </div>
                            <span className="text-3x">3x</span>
                          </div>
                          <div className="text-icon">
                            <div className="inner-text-icon">
                              <img src={dollarSign} alt="dollarSign" />
                              <span className="roi-text">GMV</span>
                            </div>
                            <span className="text-3x">$89</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="profile-price-cards">
                    <strong className="dark semi_bold">Price Videos</strong>
                    <div className="row">
                      {offeredVideos?.map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6">
                          <div className="price-cards">
                            <p>
                              {item?.count} Videos per{" "}
                              {item?.duration === "monthly" ? "month" : "week"}
                            </p>
                            <h1>${item?.price}</h1>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="profile-price-cards live-cards">
                    <h6>Price Lives</h6>
                    <div className="row">
                      {offeredLivePrice?.map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6">
                          <div className="price-cards">
                            <p>
                              {item?.count} Live per{" "}
                              {item?.duration === "monthly" ? "month" : "week"}
                            </p>
                            <h1>${item?.price}</h1>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* <div className="bordered-box-text">
                <div className="video-prices">
                  <h4>Live prices</h4>
                  <div class="price-option">
                    <div className="price-card-sec">
                      <div class="price-card">
                        <span className="alpha-text">5 Live/m</span>
                        <span className="dollar-text">250</span>
                      </div>
                    </div>
                    <div className="price-card-sec"></div>
                  </div>
                </div>
                <div className="video-prices">
                  <h4>Video prices</h4>
                  <div class="price-option">
                    <div className="price-card-sec">
                      <div class="price-card">
                        <span className="alpha-text">5 Live/m</span>
                        <span className="dollar-text">250</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>

          {!application?.isHired && (
            <div className="main-center-profile">
              <div className="center-text">
                <h6>Send note to creator</h6>
                <form onSubmit={formik.handleSubmit}>
                  <textarea
                    type="text"
                    value={formik.values.brandNote}
                    name="brandNote"
                    rows={3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`form-control ${
                      formik.touched.brandNote && formik.errors.brandNote
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Send a note to the creator if you have any issues with their proposal"
                  />
                  {formik.touched.brandNote && formik.errors.brandNote && (
                    <div className="invalid-feedback">
                      {formik.errors.brandNote}
                    </div>
                  )}
                  <Button
                    type="submit"
                    className="btn-style light-blue mt-2"
                    disabled={
                      loadings?.updateApplicationAsyncThunk || !formik.dirty
                    }
                  >
                    {loadings?.updateApplicationAsyncThunk ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <>
                        Send
                        <RiSendPlane2Fill />
                      </>
                    )}
                  </Button>
                </form>
              </div>
            </div>
          )}

          {application?.isHired && (
            <div className="bottom-box">
              <h6>Brand note</h6>
              <p>
                {application?.brandNote
                  ? application?.brandNote
                  : "No brand note"}
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default memo(ViewProfileDetailModal);
