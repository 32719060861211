import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

export default function PaginationComponent({
    totalItems=0,
    itemsPerPageOptions = [10, 20, 50],
    onPageChange,
    onItemsPerPageChange = () => {},
    initialPage = 1,
    initialItemsPerPage = 10,
}) {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
        onPageChange(page);
    };

    const handleItemsPerPageChange = (count) => {
        setItemsPerPage(count);
        setCurrentPage(1);  // Reset to page 1 whenever items per page changes
        onItemsPerPageChange(count);
    };

    const generatePageNumbers = () => {
        const maxButtons = 7;
        const pageNumbers = [];
        
        if (totalPages <= maxButtons) {
            for (let i = 1; i <= totalPages; i++) pageNumbers.push(i);
        } else {
            const start = Math.max(1, currentPage - 2);
            const end = Math.min(totalPages, currentPage + 2);
            if (start > 1) pageNumbers.push(1, "...");
            for (let i = start; i <= end; i++) pageNumbers.push(i);
            if (end < totalPages) pageNumbers.push("...", totalPages);
        }
        
        return pageNumbers;
    };

    return (
        <div className="pagination-wrapper">
            <div className="pagination-div">
                <button
                    className="func-btn"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    <FaChevronLeft /> Back
                </button>
                
                <ul className="pagination-list">
                    {generatePageNumbers().map((number, index) => (
                        <li key={index} className={number === currentPage ? 'active' : ''}>
                            {number === "..." ? (
                                <span>...</span>
                            ) : (
                                <button onClick={() => handlePageChange(number)}>{number}</button>
                            )}
                        </li>
                    ))}
                </ul>
                
                <button
                    className="func-btn"
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    Next <FaChevronRight />
                </button>
            </div>
            
            <div className="per-page-result">
                <p>Result per page</p>
                <DropdownButton
                    id="dropdown-button-drop-down"
                    className="custom-dropdown"
                    drop={'down'}
                    variant="secondary"
                    title={`${itemsPerPage}`}
                >
                    {itemsPerPageOptions.map((option, idx) => (
                        <Dropdown.Item
                            key={idx}
                            eventKey={option}
                            onClick={() => handleItemsPerPageChange(option)}
                        >
                            {option}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </div>
        </div>
    );
}
