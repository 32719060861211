export const returnArray = (arr = []) => {
  if (arr && Array.isArray(arr) && arr.length > 0) return arr;
  else return [];
};

export const getId = (obj) => {
  if (typeof obj === "object" && obj !== null && obj !== undefined) {
    return obj?.id ?? obj?._id;
  } else {
    return null;
  }
};

export const RenderMap = ({ arr = [], component, notFound }) => {
  if (arr && Array.isArray(arr) && arr.length > 0) return component;
  else return notFound;
};

export const setValueIfExist = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      (!Array.isArray(value) || value.length > 0)
    ) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const isNotVideoContent = async (url) => {
  if (!url || typeof url !== "string") {
    throw new Error("Invalid URL");
  }

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    const contentType = response.headers.get("content-type");
    console.log("contentType", contentType);
    return !contentType.includes("video");
  } catch (error) {
    console.error("Error fetching URL:", error);
    throw error;
  }
};
