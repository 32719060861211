import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// Start invite Slices
///////////////////////////////////////////////////

export const createInviteAsyncThunk = createAsyncThunk(
  "invite/createInviteAsyncThunk",
  catchAsync(async ({ data }) => {
    const response = await ApiRequests.createInvite(data);
    return response?.data;
  })
);

export const createInvitesFromJobAsyncThunk = createAsyncThunk(
  "invite/createInvitesFromJobAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    const response = await ApiRequests.createMultipleInviteFromJob(data);
    if (response.status == 200) {
      toast.success("Creator invited!");
    }

    if (callBack) callBack();
    return response?.data;
  })
);

const initialState = {
  //news states
  story: null,
  assets: null,
  asset: null,
  analytics: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  paymentMethods: [],
  order: "asce",
};

const invitationSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createInviteAsyncThunk,
            createInvitesFromJobAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default invitationSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  invitationSlice.actions;
