import React from "react";

import barsIcon from "../assets/images/icons/bars-icon.svg";

export default function CreatorAnalyticsTab() {
    return (
        <div className="creator-analytics-main-wrapper">
            <div className="section-title-with-cards">
                <strong className="semi_bold dark large">Analytics</strong>
            </div>
            <div className="creator-basic-info-cards-main-wrapper">
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <div className="info-card-wrapper light-blue-bg">
                            <div className="img-wrapper">
                                <img src={barsIcon} alt="barsIcon" />
                            </div>
                            <div className="text-wrapper">
                                <p className="medium-bold">GMV </p>
                                <strong className="large dark">$60k</strong>
                                <span className="blue">$27.80 Per Buyer</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="info-card-wrapper light-blue-bg">
                            <div className="img-wrapper">
                                <img src={barsIcon} alt="barsIcon" />
                            </div>
                            <div className="text-wrapper">
                                <p className="medium-bold">Video GPM </p>
                                <strong className="large dark">8</strong>
                                <span className="blue">$21.78</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="info-card-wrapper light-blue-bg">
                            <div className="img-wrapper">
                                <img src={barsIcon} alt="barsIcon" />
                            </div>
                            <div className="text-wrapper">
                                <p className="medium-bold">Items Sold </p>
                                <strong className="large dark">2,467</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="info-card-wrapper light-blue-bg">
                            <div className="img-wrapper">
                                <img src={barsIcon} alt="barsIcon" />
                            </div>
                            <div className="text-wrapper">
                                <p className="medium-bold">Avg. Live Viewers</p>
                                <strong className="large dark">$0</strong>
                                <span className="blue">0 Streams in 30 Days</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="info-card-wrapper light-blue-bg">
                            <div className="img-wrapper">
                                <img src={barsIcon} alt="barsIcon" />
                            </div>
                            <div className="text-wrapper">
                                <p className="medium-bold">Avg. Video Views</p>
                                <strong className="large dark">$0</strong>
                                <span className="blue">0 Streams in 30 Days</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="info-card-wrapper light-blue-bg">
                            <div className="img-wrapper">
                                <img src={barsIcon} alt="barsIcon" />
                            </div>
                            <div className="text-wrapper">
                                <p className="medium-bold">Video GPM</p>
                                <strong className="large dark">$0</strong>
                                <span className="blue">0 Streams in 30 Days</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="info-card-wrapper light-blue-bg">
                            <div className="img-wrapper">
                                <img src={barsIcon} alt="barsIcon" />
                            </div>
                            <div className="text-wrapper">
                                <p className="medium-bold">Live GPM</p>
                                <strong className="large dark">$0</strong>
                                <span className="blue">0 Streams in 30 Days</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="info-card-wrapper light-blue-bg">
                            <div className="img-wrapper">
                                <img src={barsIcon} alt="barsIcon" />
                            </div>
                            <div className="text-wrapper">
                                <p className="medium-bold">My ROI   </p>
                                <strong className="large dark">$0</strong>
                                <span className="blue">0 Streams in 30 Days</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}