import React from "react";

import noCreatorImg from "../../assets/images/no_creator.svg";

export default function NoCreatorApplicationFoundComponent() {
  return (
    <div className="no-creators-main-wrapper">
      <div className="main-wrapper">
        <div className="img-wrapper">
          <img src={noCreatorImg} alt="noCreatorImg" className="no-jobs-img" />
        </div>
        <div className="content-wrapper">
          {/* <p className="medium medium-bold">You have not posted any jobs yet</p> */}
          <div className="btn-wrapper">There are no jobs here to see</div>
        </div>
      </div>
    </div>
  );
}
