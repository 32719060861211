import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import PageTitleHeader from "../components/PageTitleHeader";
import TabsAndFilterBar from "../components/TabsAndFilterBar";

import JobsImg from "../assets/images/job-img-1.svg";
import eye from "../assets/images/icons/eye.svg";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import targetIcon from "../assets/images/icons/target-icon.svg";
import refreshIcon from "../assets/images/icons/refresh-icon-blue.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandsAsyncThunk,
  getBrandsCountByStatus,
} from "../redux/pagesSlices/brandSlice";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import CreatorCardComponent from "../components/creators/CreatorCardComponent";
import NoCreatorFoundComponent from "../components/creators/NoCreatorFoundComponent";
import BrandsRowComponent from "../components/brands/BrandsRowComponent";
import { setValueIfExist } from "../utils/utils";
import TabsAndFilterBarBrand from "../components/TabsAndFilterBarBrand";

export default function BrandsPage() {
  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [activeTab, setActiveTab] = useState("all");

  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      ...setValueIfExist({
        name: search,
        sortBy,
        limit: 10,
      }),
    };
    if (activeTab !== "all") {
      params.status = activeTab;
    }
    setTimeout(() => {
      dispatch(getBrandsAsyncThunk(params));
    }, 500);
  }, [dispatch, search, activeTab, sortBy]);

  useEffect(() => {
    const params = {
      ...setValueIfExist({
        name: search,
        // sortBy,
        limit: 10,
      }),
    };

    dispatch(getBrandsCountByStatus(params));
  }, [search]);
  const brands = useSelector((state) => state.brand?.brands);

  return (
    <div className="inner-page-wrapper active m-0">
      <div className="jobs-page">
        <PageTitleHeader page={"Brands"} />
        <TabsAndFilterBarBrand
          // exLargeContainer={true}
          largeContainer={true}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          search={search}
          setSearch={setSearch}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <div className="job-listings-section">
          <div className="container ex-large">
            <div className="listing-main-wrapper">
              <div className="table-wrapper">
                {brands?.totalResults < 1 && (
                  <table className="gapped">
                    <thead>
                      <tr>
                        <th style={{ paddingLeft: 120 }}>
                          <span className="white medium-bold">Brand</span>
                        </th>
                        <th>
                          <span className="white medium-bold">Website</span>
                        </th>
                        <th>
                          <span className="white medium-bold">No. of Jobs</span>
                        </th>
                        <th>
                          <span className="white medium-bold">
                            Member since
                          </span>
                        </th>
                        <th>
                          <span className="white medium-bold">Status</span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                  </table>
                )}
                <ProviderErrorLoadingPagination
                  reducer={"brand"}
                  action={"getBrandsAsyncThunk"}
                  asyncThunk={getBrandsAsyncThunk}
                  dataKey={"brands"}
                  Component={BrandsRowComponent}
                  emptyMessage={
                    <>
                      <NoCreatorFoundComponent />
                    </>
                  }
                  componentProps={{
                    className: "col-md-4",
                  }}
                  pagination
                  Parent={({ children }) => (
                    <table className="gapped">
                      {brands?.totalResults > 0 && (
                        <thead>
                          <tr>
                            <th style={{ paddingLeft: 120 }}>
                              <span className="white medium-bold">Brand</span>
                            </th>
                            <th>
                              <span className="white medium-bold">Website</span>
                            </th>
                            <th>
                              <span className="white medium-bold">
                                No. of Jobs
                              </span>
                            </th>
                            <th>
                              <span className="white medium-bold">
                                Member since
                              </span>
                            </th>
                            <th>
                              <span className="white medium-bold">Status</span>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                      )}
                      <tbody>{children} </tbody>
                    </table>
                  )}
                  itemKey="item"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
