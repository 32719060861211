import React from "react";

import dollarIcon from "../assets/images/icons/dollarIcon.svg";
import { returnArray } from "../utils/utils";
import { durationFormat } from "../utils/format";

export default function CreatorProfileServiceOfferedTab({ creator }) {
  return (
    <div className="creator-profile-service-offered-tab">
      <div className="section-title-with-cards">
        <strong className="semi_bold dark large">Services offered</strong>
      </div>
      <div className="video-pricintg-cards-wrapper mb-4">
        <strong className="dark  mb-2 d-block">Videos</strong>
        <div className="creator-basic-info-cards-main-wrapper">
          <div className="row">
            {returnArray(creator?.videoPrices).map((item, i) => (
              <div className="col-md-3 mb-3 mb-md-0" key={i}>
                <div className="info-card-wrapper light-blue-bg">
                  <div className="img-wrapper">
                    <img src={dollarIcon} alt="dollarIcon" />
                  </div>
                  <div className="text-wrapper">
                    <p className="medium-bold small">
                      {item?.count} Videos per {durationFormat(item?.duration)}
                    </p>
                    <strong className="large dark">$ {item?.price}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="live-pricintg-cards-wrapper mb-3">
        <strong className="dark  mb-2 d-block">Live</strong>
        <div className="creator-basic-info-cards-main-wrapper">
          <div className="row">
            {returnArray(creator?.livePrices).map((item, i) => (
              <div className="col-md-3 mb-3 mb-md-0" key={i}>
                <div className="info-card-wrapper light-blue-bg">
                  <div className="img-wrapper">
                    <img src={dollarIcon} alt="dollarIcon" />
                  </div>
                  <div className="text-wrapper">
                    <p className="medium-bold small">
                      {item?.count} Lives per {durationFormat(item?.duration)}
                    </p>
                    <strong className="large dark">$ {item?.price}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
