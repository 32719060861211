import React from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";

import pointerIcon from '../assets/images/icons/pointer-icon.svg'
import basketIcon from '../assets/images/icons/basket-icon.svg'

export default function SettingTeamsComponent({editRoleModalOpenHandler}) {
    return (
        <div className="setting-teams-tab-wrapper">
            <div className="table-wrapper">
                <table className="simple-light-table simple">
                    <thead>
                        <tr>
                            <th><span>Member</span></th>
                            <th><span>Role</span></th>
                            <th><span>Joined</span></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="main-wrapper user-info d-flex">
                                    <div className="text-wrapper">
                                        <strong className="dark semi_bold d-block">Victoria Samuel</strong>
                                        <span>lionelmessi23@gmail.com</span>
                                    </div>
                                </div>
                            </td>
                            <td><p className="light">Owner</p></td>
                            <td><p className="dark_text">Aug 10, 2024</p></td>
                            <td style={{textAlign:'end'}}>
                                <DropdownButton
                                    id={`dropdown-button-drop-down`}
                                    drop={"down"}
                                    variant="secondary"
                                    className="custom-dropdown three-dots-line"
                                    title={<BsThreeDots className='action-icon' />}
                                    style={{float : 'right'}}
                                >
                                    <Dropdown.Item
                                        eventKey="1"
                                        as={Link}
                                        onClick={editRoleModalOpenHandler}
                                    >
                                        <img src={pointerIcon} alt="pointerIcon" />
                                        Edit role
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey="2"
                                        as={Link}
                                    >
                                        <img src={basketIcon} alt="basketIcon" />
                                        Remove
                                    </Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}