import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { LuUpload } from "react-icons/lu";
import ExportDataModal from "./modals/ExportDataModal";


export default function PageTitleHeader({ page }) {
  const location = useLocation();
  const [exportDataModal, setExportDataModal] = useState(false);
  const exportDataModalOpen = () => {
    setExportDataModal(true)
  }
  const exportDataModalClose = () => {
    setExportDataModal(false)
  }

  return (
    <>
      <div className="page-title-header-component">
        <div className={`container ex-large `}>
          <div className="row">
            <div className="col-md-4 align-self-center">
              <div className="inner-page-title-wrapper">
                <h2>{page || "Jobs"}</h2>
              </div>
            </div>
            <div className="col-md-8 align-self-center">
              {location.pathname === '/dashboard/creators' && (
                <div className="btn-wrapper">
                  <a href="#" className="btn-style transparent-border" onClick={exportDataModalOpen}><LuUpload /> Export</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ExportDataModal exportDataModalClose={exportDataModalClose} exportDataModal={exportDataModal} />
    </>
  );
}
