import userblueIcon from "../assets/images/icons/user-blue-icon.svg";
import filepinkIcon from "../assets/images/icons/file-pink-icon.svg";
import bagsorangeIcon from "../assets/images/icons/bags-orange-icon.svg";
import usersgreenIcon from "../assets/images/icons/users-green-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import boxedIcon from "../assets/images/icons/boxed-arrow-icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { IoMdTrendingUp } from "react-icons/io";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import JobsImg from "../assets/images/job-img-1.svg";
import { Link } from "react-router-dom";
import TotalUsersChart from "../components/charts/TotalUsersChart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getId, returnArray, setValueIfExist } from "../utils/utils";
import {
  getJobsAsyncThunk,
  updateJobAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { toTitleCase } from "../utils/methods";
import { Spinner } from "react-bootstrap";
import { getCreatorsAsyncThunk } from "../redux/pagesSlices/creatorSlice";
import { dateFormat } from "../utils/format";
import { getBrandsAsyncThunk } from "../redux/pagesSlices/brandSlice";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import JobCardComponentDashboard from "../components/jobs/JobCardComponentDashboard";
import NoCreatorFoundComponent from "../components/creators/NoCreatorFoundComponent";
import CreatorCardComponentDashboard from "../components/creators/CreatorCardComponentDashboard";
import BrandCardComponentDashboard from "../components/brands/BrandCardComponentDashboard";
import {
  getAdminAnalyticsAsyncThunk,
  getAdminDashboardStatsAsyncThunk,
  getTopCreatorsAsyncThunk,
} from "../redux/pagesSlices/adminSlice";

export default function DashboardHome() {
  const auth = useSelector((s) => s.auth);
  const { analytics, dashboardGraph, loadings, topCreators } = useSelector(
    (s) => s.admin
  );
  const [loadingJobId, setLoadingJobId] = useState({});
  const [duration, setDuration] = useState("weekly");
  const dispatch = useDispatch();

  const currentUser = auth?.user;
  // job params
  const jobsParams = {
    ...setValueIfExist({
      populate: "brandId",
      sortBy: "createdAt:desc",
      limit: 5,
    }),
  };
  // creator params
  const creatorParams = {
    ...setValueIfExist({
      sortBy: "createdAt:desc",
      limit: 5,
    }),
  };
  // brand params
  const brandParams = {
    ...setValueIfExist({
      sortBy: "createdAt:desc",
      limit: 5,
    }),
  };
  // top creators params
  const topCreatorsParams = {
    ...setValueIfExist({
      page: 1,
    }),
  };

  useEffect(() => {
    dispatch(getAdminAnalyticsAsyncThunk({}));
    dispatch(getTopCreatorsAsyncThunk({ params: topCreatorsParams }));
    dispatch(getJobsAsyncThunk(jobsParams));
    dispatch(getCreatorsAsyncThunk(creatorParams));
    dispatch(getBrandsAsyncThunk(brandParams));
  }, [dispatch]);

  useEffect(() => {
    const graphParams = {
      ...setValueIfExist({
        duration,
      }),
    };
    dispatch(getAdminDashboardStatsAsyncThunk({ params: graphParams }));
  }, [dispatch, duration]);

  return (
    <div className="dashboard-page">
      <div className="inner-page-wrapper active m-0">
        <div className="dashboard-page-home">
          <div className="container ex-large">
            <div className="dashboard-cards-wrapper mb-4">
              <div className="welcome-title-wrapper">
                <span>Admin</span>
                <h2>Welcome, {currentUser?.firstName} 👋</h2>
              </div>
              <div className="row">
                <div className="col-md-3 mb-3 mb-md-0">
                  <div className="card-wrapper">
                    <div className="main-wrapper">
                      <div className="content-wrapper">
                        <p className=" medium-bold">Tot. Users</p>
                        <h3 className="large">
                          {analytics?.userData?.usersCount}
                        </h3>
                      </div>
                      <div className="img-wrapper light-blue">
                        <img src={userblueIcon} alt="userblueIcon" />
                      </div>
                    </div>

                    <div className="short-des">
                      <span className="small">
                        Creators:{" "}
                        <span style={{ color: "#7FB519" }}>
                          {analytics?.userData?.creatorsCount}
                        </span>
                      </span>
                      <span className="">
                        Brands:{" "}
                        <span style={{ color: "#7FB519" }}>
                          {analytics?.userData?.brandsCount}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-3 mb-md-0">
                  <div className="card-wrapper">
                    <div className="main-wrapper">
                      <div className="content-wrapper">
                        <p className=" medium-bold">Tot. Applications</p>
                        <h3 className="large">
                          {analytics?.applicationData?.applicationsCount}
                        </h3>
                      </div>
                      <div className="img-wrapper light-pink">
                        <img src={filepinkIcon} alt="filepinkIcon" />
                      </div>
                    </div>
                    <div className="short-des">
                      <span className="">
                        <IoMdTrendingUp />{" "}
                        <span style={{ color: "#7FB519" }}>
                          {analytics?.applicationData?.growthPercentage}%
                        </span>{" "}
                        Up this week{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-3 mb-md-0">
                  <div className="card-wrapper">
                    <div className="main-wrapper">
                      <div className="content-wrapper">
                        <p className=" medium-bold">Tot. Jobs</p>
                        <h3 className="large">
                          {analytics?.jobsData?.jobsCount}
                        </h3>
                      </div>
                      <div className="img-wrapper light-orange">
                        <img src={bagsorangeIcon} alt="bagsorangeIcon" />
                      </div>
                    </div>
                    <div className="short-des">
                      <span className="">
                        <IoMdTrendingUp />{" "}
                        <span style={{ color: "#7FB519" }}>
                          {analytics?.jobsData?.growthPercentage}%
                        </span>{" "}
                        Up this week{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-3 mb-md-0">
                  <div className="card-wrapper">
                    <div className="main-wrapper">
                      <div className="content-wrapper">
                        <p className=" medium-bold">Hired creators</p>
                        <h3 className="large">
                          {analytics?.hiredCreatorsData?.hiredCreatorsCount}
                        </h3>
                      </div>
                      <div className="img-wrapper light-green">
                        <img src={usersgreenIcon} alt="usersgreenIcon" />
                      </div>
                    </div>
                    <div className="short-des">
                      <span className="">
                        <IoMdTrendingUp />{" "}
                        <span style={{ color: "#7FB519" }}>
                          {analytics?.hiredCreatorsData?.growthPercentage}%
                        </span>{" "}
                        Up this week{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="total-users-creators-section">
              <div className="row">
                <div className="col-md-9 mb-4">
                  <div className="card-main-wrapper">
                    <div className="main-title-wrapper">
                      <strong className="medium">Total Users</strong>
                      <ul className="list">
                        <li>
                          <span style={{ background: "#578BFC" }}></span>Creator
                        </li>
                        <li>
                          <span style={{ background: "#F335B9" }}></span>Brand
                        </li>
                      </ul>
                      <div className="custom-dropdown dropdown">
                        <DropdownButton
                          id="dropdown-button-drop-down"
                          className="custom-dropdown"
                          drop={"down"}
                          onSelect={setDuration}
                          variant="secondary"
                          title={toTitleCase(duration)}
                        >
                          <Dropdown.Item eventKey="weekly">
                            Weekly
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="monthly">
                            Monthly
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="yearly">
                            Yearly
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                    <div className="chart-wrapper">
                      <TotalUsersChart
                        graphData={dashboardGraph}
                        graphLoading={
                          loadings?.getAdminDashboardStatsAsyncThunk
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="card-main-wrapper">
                    <div className="main-title-wrapper">
                      <strong className="medium">Top creators</strong>
                    </div>
                    <div className="creators-main-wrapper">
                      {topCreators?.results?.map((item, i) => (
                        <div className="creator-wrapper" key={i}>
                          <div className="main-wrapper">
                            <div className="img-wrapper">
                              <img
                                src={
                                  item?.avatar
                                    ? parseImagePathToURL(item?.avatar)
                                    : avatarIcon
                                }
                                alt="avatarIcon"
                              />
                            </div>
                            <div className="text-wrapper">
                              <strong className="dark">
                                {item?.name || item?.tikTokUserName}
                              </strong>
                              <span>@{item?.userName}</span>
                            </div>
                          </div>
                          <div className="percentage-wrapper">
                            <span className="light-green">{item?.percentage}%</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="card-main-wrapper">
                    <div className="main-title-wrapper">
                      <strong>New Jobs</strong>
                      <div className="arrow-icon">
                        <Link to="/dashboard/jobs">
                          <img src={boxedIcon} alt="boxedIcon" />
                        </Link>
                      </div>
                    </div>
                    <div className="new-jobs-main-wrapper table-wrapper over_flow">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            {/*<th></th>*/}
                          </tr>
                        </thead>
                        <tbody>
                          <ProviderErrorLoadingPagination
                            reducer="job"
                            action="getJobsAsyncThunk"
                            asyncThunk={getJobsAsyncThunk}
                            dataKey="jobs"
                            Component={JobCardComponentDashboard}
                            emptyMessage={
                              <>
                                <NoCreatorFoundComponent />
                              </>
                            }
                            componentProps={{
                              jobsParams,
                              setLoadingJobId,
                              loadingJobId,
                            }}
                            pagination={false}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="card-main-wrapper">
                    <div className="main-title-wrapper">
                      <strong>Recent Creators</strong>
                      <div className="arrow-icon">
                        <Link to="/dashboard/creators">
                          <img src={boxedIcon} alt="boxedIcon" />
                        </Link>
                      </div>
                    </div>
                    <div className="new-jobs-main-wrapper table-wrapper over_flow">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ProviderErrorLoadingPagination
                            reducer="creator"
                            action="getCreatorsAsyncThunk"
                            asyncThunk={getCreatorsAsyncThunk}
                            dataKey="creators"
                            Component={CreatorCardComponentDashboard}
                            emptyMessage={
                              <>
                                <NoCreatorFoundComponent />
                              </>
                            }
                            componentProps={{
                              creatorParams,
                            }}
                            pagination={false}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="card-main-wrapper">
                    <div className="main-title-wrapper">
                      <strong>Recent Brands</strong>
                      <div className="arrow-icon">
                        <Link to="/dashboard/brands">
                          <img src={boxedIcon} alt="boxedIcon" />
                        </Link>
                      </div>
                    </div>
                    <div className="new-jobs-main-wrapper table-wrapper over_flow">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ProviderErrorLoadingPagination
                            reducer="brand"
                            action="getBrandsAsyncThunk"
                            asyncThunk={getBrandsAsyncThunk}
                            dataKey="brands"
                            Component={BrandCardComponentDashboard}
                            emptyMessage={
                              <>
                                <NoCreatorFoundComponent />
                              </>
                            }
                            componentProps={{
                              brandParams,
                            }}
                            pagination={false}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
