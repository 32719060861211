export const timeZones = [
    { label: "UTC - 12:00 International Date Line West", value: "UTC-12:00" },
    { label: "UTC - 11:00 Coordinated Universal Time-11", value: "UTC-11:00" },
    { label: "UTC - 10:00 Hawaii", value: "UTC-10:00" },
    { label: "UTC - 09:00 Alaska", value: "UTC-09:00" },
    { label: "UTC - 08:00 Pacific Time (US & Canada)", value: "UTC-08:00" },
    { label: "UTC - 07:00 Mountain Time (US & Canada)", value: "UTC-07:00" },
    { label: "UTC - 06:00 Central Time (US & Canada)", value: "UTC-06:00" },
    { label: "UTC - 05:00 Eastern Time (US & Canada)", value: "UTC-05:00" },
    { label: "UTC - 04:00 Atlantic Time (Canada)", value: "UTC-04:00" },
    { label: "UTC - 03:00 Buenos Aires, Georgetown", value: "UTC-03:00" },
    { label: "UTC - 02:00 Mid-Atlantic", value: "UTC-02:00" },
    { label: "UTC - 01:00 Azores, Cape Verde Islands", value: "UTC-01:00" },
    { label: "UTC + 00:00 Greenwich Mean Time (GMT)", value: "UTC+00:00" },
    { label: "UTC + 01:00 Central European Time (CET)", value: "UTC+01:00" },
    { label: "UTC + 02:00 Eastern European Time (EET)", value: "UTC+02:00" },
    { label: "UTC + 03:00 Moscow Standard Time (MSK)", value: "UTC+03:00" },
    { label: "UTC + 03:30 Tehran", value: "UTC+03:30" },
    { label: "UTC + 04:00 Gulf Standard Time (GST)", value: "UTC+04:00" },
    { label: "UTC + 04:30 Kabul", value: "UTC+04:30" },
    { label: "UTC + 05:00 Pakistan Standard Time (PST)", value: "UTC+05:00" },
    { label: "UTC + 05:30 Indian Standard Time (IST)", value: "UTC+05:30" },
    { label: "UTC + 05:45 Kathmandu", value: "UTC+05:45" },
    { label: "UTC + 06:00 Bangladesh Standard Time (BST)", value: "UTC+06:00" },
    { label: "UTC + 06:30 Yangon", value: "UTC+06:30" },
    { label: "UTC + 07:00 Indochina Time (ICT)", value: "UTC+07:00" },
    { label: "UTC + 08:00 China Standard Time (CST)", value: "UTC+08:00" },
    { label: "UTC + 09:00 Japan Standard Time (JST)", value: "UTC+09:00" },
    { label: "UTC + 09:30 Australian Central Time (ACT)", value: "UTC+09:30" },
    { label: "UTC + 10:00 Australian Eastern Time (AET)", value: "UTC+10:00" },
    { label: "UTC + 11:00 Solomon Islands, Vanuatu", value: "UTC+11:00" },
    { label: "UTC + 12:00 Fiji, Kamchatka, New Zealand", value: "UTC+12:00" },
    { label: "UTC + 13:00 Samoa, Tonga", value: "UTC+13:00" },
    { label: "UTC + 14:00 Line Islands", value: "UTC+14:00" },
  ];
  