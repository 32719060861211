import React, { memo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import JobsImg from "../../assets/images/job-img-1.svg";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { toTitleCase } from "../../utils/methods";
import { getId } from "../../utils/utils";

const JobCardComponentDashboard = memo(({ item = {} }) => {
  return (
    <tr>
      <td style={{ paddingLeft: 0 }}>
        <div className="main-wrapper">
          <div className="img-wrapper">
            <a href="#">
              <img
                src={
                  item?.image?.length > 0
                    ? parseImagePathToURL(item?.image[0])
                    : JobsImg
                }
                alt="JobsImg"
              />
            </a>
          </div>
          <div className="text-wrapper">
            <strong>
              <Link to={`/dashboard/job-detail/${getId(item)}`}>
                {item.jobTitle}
              </Link>
            </strong>
          </div>
        </div>
      </td>
      <td>
        <span>{item?.brandId?.userName || item?.brandName}</span>
      </td>
      <td style={{ textAlign: "end", paddingRight: 0 }}>
        <p className={`status-text ${item.status}`}>
          {toTitleCase(item.status)}
        </p>
      </td>
    </tr>
  );
});

export default JobCardComponentDashboard;
