import React, { memo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { toTitleCase } from "../../utils/methods";
import { getId } from "../../utils/utils";
import eyeIcon from "../../assets/images/icons/eye-icon.svg";
import avatarIcon from "../../assets/images/icons/avatar-icon.svg";

const BrandCardComponentDashboard = memo(({ item = {} }) => {
  return (
    <tr>
      <td style={{paddingLeft : 0}}>
        <div className="main-wrapper small-img">
          <div className="img-wrapper">
            <Link to={`/dashboard/brand-detail/${getId(item)}`}>
              <img
                src={
                  item?.avatar ? parseImagePathToURL(item?.avatar) : avatarIcon
                }
                alt="avatarIcon"
                className="rounded"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong>
              <Link to={`/dashboard/brand-detail/${getId(item)}`}>
                {item?.name}
              </Link>
            </strong>
            <span>{item?.email}</span>
          </div>
        </div>
      </td>
      <td>
        <p className="dark_text">
          <Link to={item?.website} target="_blank">
            {item?.website}
          </Link>
        </p>
      </td>
      <td style={{ textAlign: "end", paddingRight: 0 }}>
        <div className="action-btn-wrapper">
          <Link to={`/dashboard/brand-detail/${getId(item)}`}>
            <div className="icon-wrapper rounded medium">
              <img src={eyeIcon} alt="eyeIcon" />
            </div>
          </Link>
        </div>
      </td>
    </tr>
  );
});

export default BrandCardComponentDashboard;
