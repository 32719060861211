import React, { useEffect, useState } from "react";

import masterCard from "../assets/images/icons/master-card-icon.svg";
import { TfiDownload } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import { getCardDetailAsyncThunk } from "../redux/pagesSlices/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { returnArray } from "../utils/utils";
import LoaderComponent from "./LoaderComponent";
import moment from "moment";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";

export default function PaymentAndSubscriptions() {
  const { id } = useParams();
  const { loadings } = useSelector((s) => s.payment);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [lastInvoiceId, setLastInvoiceId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      limit: pageSize,
      page: currentPage,
      lastInvoiceId: lastInvoiceId,
    };
    try {
      dispatch(
        getCardDetailAsyncThunk({
          id,
          params,
          callBack: () => {
            // Optional callback if needed
          },
        })
      );
    } catch (error) {
      console.error("Error fetching billing history:", error);
    }
  }, [dispatch, id, lastInvoiceId, currentPage]);
  const cardDetails = useSelector((state) => state.payment.cardDetails);

  const invoicesData = returnArray(cardDetails?.invoices);

  const totalRecords = cardDetails?.totalRecords;

  const handleNextPage = () => {
    if (cardDetails?.hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
      // Update lastInvoiceId for the next page
      setLastInvoiceId(invoicesData[invoicesData.length - 1]?.id);
    }
  };

  // Handle Previous Page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      // For going to the previous page, the lastInvoiceId will be handled on the backend
      setLastInvoiceId(null); // Reset for the previous page
    }
  };

  function formatString(input) {
    return input
      ?.replace(/_/g, " ") // Replace underscores with spaces
      ?.replace(/\b\w/g, (char) => char?.toUpperCase()); // Capitalize the first letter of each word
  }

  return (
    <div className="payment-andsubscriptions-main-wrapper">
      <div className="title mb-4">
        <strong className="dark large">Payment method</strong>
      </div>
      {loadings?.getCardDetailAsyncThunk ? (
        <LoaderComponent />
      ) : (
        <>
          {cardDetails?.cardDetails ? (
            <div className="payment-methhod-wrapper">
              <div className="card-icon-wrapper">
                <div className="icon">
                  <img src={masterCard} alt="masterCard" />
                </div>
                <div className="text-wrapper">
                  <strong className="dark medium semi_bold d-block text-capitalize">
                    {cardDetails?.cardDetails?.brand || "Mastercard"}
                  </strong>
                  <strong className="d-bock medium">
                    *****{cardDetails?.cardDetails?.last4 || "1234"}
                  </strong>
                </div>
              </div>
              <div className="date">
                <p className="medium-bold mb-0">
                  Exp. Date{" "}
                  {cardDetails?.cardDetails?.expMonth?.length === 1
                    ? `0${cardDetails?.cardDetails?.expMonth}`
                    : cardDetails?.cardDetails?.expMonth || "00"}
                  /
                  {cardDetails?.cardDetails?.expYear?.toString()?.slice(-2) ||
                    "00"}
                </p>
              </div>
            </div>
          ) : (
            <div className="payment-methhod-wrapper">
              This brand has not added a payment method yet.
            </div>
          )}

          <div className="title mb-4">
            <strong className="dark large">Billing history </strong>
          </div>
          <div className="table-wrapper">
            <div>
              {invoicesData?.length === 0 ? (
                <>No Data Found</>
              ) : (
                <>
                  <table className="simple-light-table simple">
                    <thead>
                      <tr>
                        <th>
                          <span className="dark">Date</span>
                        </th>
                        <th>
                          <span className="dark">Amount</span>
                        </th>
                        <th>
                          <span className="dark">Description</span>
                        </th>
                        <th>
                          <span className="dark">Method</span>
                        </th>
                        <th style={{ textAlign: "end" }}>
                          <span className="dark">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoicesData?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <p>
                              {moment(item?.created).format("MMMM DD, YYYY")}
                            </p>
                          </td>
                          <td>
                            <p>${item?.amount_due}</p>
                          </td>
                          <td>
                            <p>
                              {item?.description
                                ? formatString(item?.description)
                                : "No Description"}
                            </p>
                          </td>
                          <td className="status text-capitalize">
                            {item?.method || "N/A"}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <button className="custom-btn trasparent-btn blue">
                              <TfiDownload
                                style={{
                                  width: 18,
                                  height: 20,
                                  color: "#578BFC",
                                }}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="subscription-pagination">
                    <div className="inner-wrapper">
                      <div className="counting">
                        Showing {(currentPage - 1) * pageSize + 1} to{" "}
                        {Math.min(currentPage * pageSize, totalRecords)} of{" "}
                        {totalRecords} entries
                      </div>

                      <div className="controls">
                        <div className="single-control">
                          <button
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                          >
                            <LuChevronLeft />
                          </button>
                        </div>
                        <div className="single-control">
                          <button
                            onClick={handleNextPage}
                            disabled={!cardDetails?.hasMore}
                          >
                            <LuChevronRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
