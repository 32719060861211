import React, { memo, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import JobsImg from "../../assets/images/job-img-1.svg";
import eye from "../../assets/images/icons/eye.svg";
import eyeIcon from "../../assets/images/icons/eye-icon.svg";
import shopIcon from "../../assets/images/icons/shop-icon.svg";
import targetIcon from "../../assets/images/icons/target-icon.svg";
import refreshIcon from "../../assets/images/icons/refresh-icon-blue.svg";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { toTitleCase } from "../../utils/methods";
import { dateFormat } from "../../utils/format";
import { getId } from "../../utils/utils";
import { BsInfoCircleFill } from "react-icons/bs";

const CreatorApplicationCardComponent = memo(({ item = {} }) => {
  const jobDetails = item.jobId;
  const brandDetails = item.brandId;
  return (
    <tr>
      <td>
        <div className="main-wrapper">
          <div className="img-wrapper">
            <Link to="/dashboard/job-detail">
              <img
                src={
                  jobDetails?.image?.length > 0
                    ? parseImagePathToURL(jobDetails?.image[0])
                    : JobsImg
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong className="dark semi_bold">
              <Link to="/dashboard/job-detail">{jobDetails?.jobTitle}</Link>
            </strong>
          </div>
        </div>
      </td>
      <td>
        <div className="main-wrapper ex-small">
          <div className="img-wrapper">
            <Link to="/dashboard/job-detail">
              <img
                src={
                  brandDetails?.avatar
                    ? parseImagePathToURL(brandDetails?.avatar)
                    : JobsImg
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong className="dark semi_bold">
              <Link to="/dashboard/job-detail">
                {brandDetails?.name || brandDetails?.userName}
              </Link>
            </strong>
          </div>
        </div>{" "}
      </td>
      <td>
        <p
          className={`dark status-text ${
            item?.status === "pending" ? "active" : item?.status
          } `}
        >
          {item?.status === "pending" ? "Active" : toTitleCase(item?.status)}
        </p>
      </td>
      <td>
        <p className="dark">{dateFormat(item?.createdAt)}</p>{" "}
      </td>
      <td>
        {item?.status === "pending" ? (
          <button className="btn-style blue-btn">Track</button>
        ) : (
          <p className="dark">{dateFormat(item?.endTime) || "N/A"}</p>
        )}
      </td>
    </tr>
  );
});

export default CreatorApplicationCardComponent;
