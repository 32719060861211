import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import sampleImage from "../../assets/images/proposal-img.svg";
import videoMediaImage from "../../assets/images/video-media-img.svg";
import JobDetailFullSection from "../JobDetailFullSection";
import { FaPlay } from "react-icons/fa";
import videoIcon from "../../assets/images/icons/video-file-wrapper.svg";
import LiveIcon from "../../assets/images/icons/live-icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { RiTimerFill } from "react-icons/ri";
import { BsThreeDots } from "react-icons/bs";
import EditTimeModal from "./EditTimeModal";
import { useDispatch, useSelector } from "react-redux";
import { getJobAsyncThunk } from "../../redux/pagesSlices/jobSlice";
import { returnArray } from "../../utils/utils";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import moment from "moment";

export default function ViewJobDetailCenteredModal({
  viewJobDetailCenteredModal,
  handleViewJobCenteredModalClose,
  item,
}) {
  console.log("id and value", item);

  const [status, setStatus] = useState(false);
  const [editTimeModal, setEditTimeModal] = useState(false);
  const editTimeModalOpenHandler = () => {
    setEditTimeModal(true);
  };
  const editTimeModalCloseHandler = () => {
    setEditTimeModal(false);
  };
  const [loading, setLoading] = useState(false); // Track data fetching status
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     if (id) {
  //     //   dispatch(getJobAsyncThunk(id))
  //         // .finally(() => setLoading(false)); // Stop loading regardless of success or failure
  //     }
  //   }, [dispatch]);

  //   const job = useSelector((state) => state.job.job);

  //  console.log("job", job);

  const jobImages = returnArray(item?.image);
  const videosArray = returnArray(item?.videoExamples);

  const formatDate = (dateString) => {
    if (!dateString) return "Invalid date";
    
    // Format date as "MM/DD/YYYY"
    const formattedDate = moment(dateString).format("MM/DD/YYYY");
  
    // Calculate relative time (e.g., "1 week ago")
    const relativeTime = moment(dateString).fromNow();
  
    return `${formattedDate} (${relativeTime})`;
  };

  return (
    <>
      <div className="view-job-detil-modal-wrapper ">
        <Modal
          show={viewJobDetailCenteredModal}
          onHide={handleViewJobCenteredModalClose}
          className="view-job-detail-modal custom-modal centered"
          centered
        >
          <Modal.Header>
            <Button
              variant="close"
              onClick={handleViewJobCenteredModalClose}
              aria-label="Close"
              className="cancel-btn "
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div className="job-detail-modal-body-wrapper">
              <div className="project-detail-section">
                <div className="main-inner-wrapper">
                  <div className="container-fluid ">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="about-project-detal-wrapper">
                          <div className="project-stats-section">
                            <div className="single-item">
                              <h5>Videos</h5>
                              <span>5</span>
                            </div>
                            <div className="single-item">
                              <h5>Lives</h5>
                              <span>3</span>
                            </div>
                            <div className="single-item">
                              <h5>Application deadline</h5>
                              <span>10/02/2024</span>
                            </div>
                          </div>
                          <div className="about-job-content-wrapper">
                            <div className="text-wrapper">
                              <strong className="medium">
                                Job description
                              </strong>
                              <p>{item?.jobAttributes?.creatorType}</p>
                            </div>
                            <div className="text-wrapper">
                              <strong className="medium"> Pain points</strong>
                              <p>{item?.jobAttributes?.painPoints}</p>
                            </div>
                            <div className="text-wrapper">
                              <strong className="medium">
                                Content Strategy Guidance
                              </strong>
                              <p>{item?.jobAttributes?.contentStrategy}</p>
                            </div>
                            <div className="text-wrapper">
                              <strong className="medium">Job benefits</strong>
                              <p>{item?.jobAttributes?.benefits}</p>
                            </div>
                          </div>
                          <div className="media-and-resources-wrapper">
                            <div className="media-wrapper">
                              <strong className="bold dark">Photos</strong>
                              <ul className="images-media-wrapper">
                                {jobImages?.map((image, index) => (
                                  <li key={index}>
                                    <img
                                      src={parseImagePathToURL(image)}
                                      alt="proposalImg"
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="media-wrapper pb-0">
                              <strong className="bold dark">Videos</strong>
                              <ul className="videos-media-wrapper">
                                {videosArray.length > 0 ? (
                                  videosArray.map((video, index) => (
                                    <li key={index}>
                                      <video
                                        controls
                                        width="600"
                                        poster={videoMediaImage || ""}
                                      >
                                        <source src={video} type="video/mp4" />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                      <div className="icon-wrapper">
                                        <FaPlay />
                                      </div>
                                    </li>
                                  ))
                                ) : (
                                  <li>
                                    <p>No videos available</p>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="summary-main-wrapper">
                          <strong className="large dark">Job details</strong>
                          <div className="top-bar-wrapper">
                            <div className="text">
                              <strong>Job posted</strong>
                              <p className="dark_text semi_bold">
                              {item?.createdAt ? formatDate(item.createdAt) : "No date available"}
                              </p>
                            </div>
                            <div className="text">
                              <strong>Content type</strong>
                              <ul className="proposal-type-list">
                                <li className={`light-gray-bg`}>
                                  <img src={videoIcon} alt="Icon" />
                                  {"Video"}
                                </li>
                                <li className={`light-pink-bg`}>
                                  <img src={LiveIcon} alt="Icon" />
                                  {"Live"}
                                </li>
                              </ul>
                            </div>

                            <div className="text">
                              <strong>Categories</strong>
                              <ul className="categories-list">
                                <li>{returnArray(item?.categories)}</li>
                              </ul>
                            </div>
                            <div className="text">
                              <strong>Preferred ages</strong>
                              <p className="light-bg">
                                {returnArray(item?.ageRange)}
                              </p>
                            </div>
                            <div className="text">
                              <strong>Preferred gender</strong>
                              <ul className="categories-list">
                                <li>{item?.gender}</li>
                              </ul>
                            </div>
                            <div className="text">
                              <strong>Location</strong>
                              <p className="dark_text medium semi_bold">
                                United States
                              </p>
                            </div>
                            <div className="text">
                              <strong>Language</strong>
                              <p className="dark_text medium semi_bold">
                                English
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <EditTimeModal
        editTimeModalCloseHandler={editTimeModalCloseHandler}
        editTimeModal={editTimeModal}
      />
    </>
  );
}
