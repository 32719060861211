import moment from "moment/moment";

export const currency = (arr = []) => {
  if (arr && Array.isArray(arr) && arr.length > 0) return arr;
  else return [];
};

export const isValidDate = (date) => {
  if (!date) return false;
  const parsedDate = new Date(date);
  return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
};

export const dateFormat = (date) => {
  if (!isValidDate(date)) return "";
  return moment(date).format("DD/MM/YY");
};
export const durationFormat = (duration) => {
  switch (duration) {
    case "weekly":
      return "week";
    case "monthly":
      return "month";
    case "yearly":
      return "year";
    default:
      return duration;
  }
};

export const showUserName = (user) => {
  if (user && typeof user === "object") {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user.userName) {
      return user.userName;
    }
    return user.email;
  } else {
    return "Demo User";
  }
};
