import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import PageTitleHeader from "../components/PageTitleHeader";

import JobsImg from "../assets/images/job-img-1.svg";
import eye from "../assets/images/icons/eye.svg";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import targetIcon from "../assets/images/icons/target-icon.svg";
import { setValueIfExist } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobsAsyncThunk,
  getJobsCountAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import JobCardComponent from "../components/jobs/JobCardComponent";
import NoCreatorFoundComponent from "../components/creators/NoCreatorFoundComponent";
import TabsAndFilterBarJob from "../components/TabsAndFilterBarJob";

export default function JobsPage() {
  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [activeTab, setActiveTab] = useState("pending");
  const { jobs } = useSelector((s) => s.job);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      ...setValueIfExist({
        jobTitle: search,
        sortBy,
        populate: "brandId",
        status: activeTab,
      }),
    };

    setTimeout(() => {
      dispatch(getJobsAsyncThunk(params));
    }, 500);
  }, [search, dispatch, activeTab, sortBy]);

  useEffect(() => {
    const params = {
      ...setValueIfExist({
        jobTitle: search,
      }),
    };
    setTimeout(() => {
      dispatch(getJobsCountAsyncThunk(params));
    }, 500);
  }, [search, dispatch]);

  return (
    <div className="inner-page-wrapper active m-0">
      <div className="jobs-page">
        <PageTitleHeader page={"Jobs"} />
        <TabsAndFilterBarJob
          // exLargeContainer={true}
          largeContainer={true}
          setActiveTab={setActiveTab}
          sortBy={sortBy}
          setSortBy={setSortBy}
          activeTab={activeTab}
          setSearch={setSearch}
          search={search}
        />
        <div className="job-listings-section">
          <div className="container ex-large">
            <div className="listing-main-wrapper">
              <div className="table-wrapper">
                {jobs?.totalResults < 1 && (
                  <table className="gapped">
                    <thead>
                      <tr>
                        <th style={{ paddingLeft: 120 }}>
                          <span className="white medium-bold">Title</span>
                        </th>
                        <th>
                          <span className="white medium-bold">Posted by</span>
                        </th>
                        {activeTab === "approved" && (
                          <th>
                            <span className="white medium-bold">Proposals</span>
                          </th>
                        )}
                        <th>
                          <span className="white medium-bold">Status</span>
                        </th>
                        {activeTab !== "closed" && (
                          <th>
                            <span className="white medium-bold">Posted on</span>
                          </th>
                        )}
                        <th></th>
                      </tr>
                    </thead>
                  </table>
                )}
                <ProviderErrorLoadingPagination
                  reducer="job"
                  action="getJobsAsyncThunk"
                  asyncThunk={getJobsAsyncThunk}
                  dataKey="jobs"
                  Component={JobCardComponent}
                  emptyMessage={
                    <>
                      <NoCreatorFoundComponent />
                    </>
                  }
                  componentProps={{
                    className: "col-md-4",
                  }}
                  Parent={({ children }) => (
                    <table className="gapped">
                      {jobs?.totalResults > 0 && (
                        <thead>
                          <tr>
                            <th style={{ paddingLeft: 120 }}>
                              <span className="white medium-bold">Title</span>
                            </th>
                            <th>
                              <span className="white medium-bold">
                                Posted by
                              </span>
                            </th>
                            {activeTab === "approved" && (
                              <th>
                                <span className="white medium-bold">
                                  Proposals
                                </span>
                              </th>
                            )}
                            <th>
                              <span className="white medium-bold">Status</span>
                            </th>
                            {activeTab !== "closed" && (
                              <th>
                                <span className="white medium-bold">
                                  Posted on
                                </span>
                              </th>
                            )}
                            <th></th>
                          </tr>
                        </thead>
                      )}
                      <tbody>{children} </tbody>
                    </table>
                  )}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
