import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
const toast = { error: () => {}, success: () => {} };
// Start User Slices
///////////////////////////////////////////////////

export const getUsersAsyncThunk = createAsyncThunk(
  "user/getUsersAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getUsers(params);
    return response?.data;
  })
);

export const getUserAsyncThunk = createAsyncThunk(
  "user/getUserAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getUser(id);
    return response?.data;
  })
);

export const createUserAsyncThunk = createAsyncThunk(
  "user/createUserAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    // const state = getState();
    const response = await ApiRequests.createUser(data);
    if (response.status == 204) {
      toast.success("User Create Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getUsersAsyncThunk({ ...params, populate: "user_id", role: "User" })
    );
    // dispatch(getUsersByIdsAsyncThunk({ ...state.users?.paramsForThunk?.getUsersByIdsAsyncThunk}))
    return response?.data;
  })
);

export const updateUserAsyncThunk = createAsyncThunk(
  "user/updateUserAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.updateUser({ id, data });
    if (response.status == 204) {
      toast.success("User Updated Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getUsersAsyncThunk({ ...params, populate: "user_id", role: "User" })
    );
    // dispatch(getUsersByIdsAsyncThunk({ populate: "image,user_id", ...state.users?.paramsForThunk?.getUsersByIdsAsyncThunk, page: 1 }))
    return response?.data;
  })
);

export const deleteUserAsyncThunk = createAsyncThunk(
  "user/deleteUserAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteUser(id);
    if (response.status == 204) {
      toast.success("User Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(
        getUsersAsyncThunk({ ...params, populate: "user_id", role: "User" })
      );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);


export const getUserSubscriptions = createAsyncThunk(
  "user/getUserSubscriptions",
  catchAsync(async (params, { dispatch, getState }) => {
    const response = await ApiRequests.getUserSubscriptions(params);
    return response?.data;
  })
)

export const getAdminAnalytics = createAsyncThunk(
  "user/getAdminAnalytics",
  catchAsync(async (params, { dispatch, getState }) => {
    const response = await ApiRequests.getUserAnalytics(params);
    return response?.data;
  })
)
///////////////////////////////////////////////////

const initialState = {
  //news states
  users: {
    page: 1,
    users: [],
    totalPages: 1,
  },
  usersCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  inviteUser: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userExport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  userRole: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  usersList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  story: null,
  user:null,
  assets: null,
  asset: null,
  subscriptions: null,
  analytics:null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getUsersAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.users = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getUsersAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.users = {
            ...action.payload,
            users: state?.users?.results.concat(action?.payload?.results),
          };
        } else {
          state.users = action.payload;
        }
      })
      .addCase(getUserAsyncThunk.fulfilled, (state, action) => {
        
          state.user = action.payload;
        
      })
      .addCase(getUserAsyncThunk.pending, (state, action) => {
        
        state.user = null;
      
    })
    .addCase(deleteUserAsyncThunk.fulfilled, (state, action) => {
        state.users = {
          ...state.users,
          totalResults: state.users?.totalResults - 1,
          results: state.users?.results.filter((e) => e.id != action.payload),
        };
        state.usersCount = {
          ...state.usersCount,
          totalResults: state.usersCount?.totalResults - 1,
          results: state.usersCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })
      .addCase(getUserSubscriptions.fulfilled, (state, action) => {
        state.subscriptions = action.payload;
      })
      .addCase(getAdminAnalytics.fulfilled, (state, action) => {
        state.analytics = action.payload;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getUsersAsyncThunk,
            getUserAsyncThunk,
            deleteUserAsyncThunk,
            createUserAsyncThunk,
            updateUserAsyncThunk,
            getUserSubscriptions,
            getAdminAnalytics
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default userSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  userSlice.actions;
