import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { LuPlus } from "react-icons/lu";
import Dropdown from "react-bootstrap/Dropdown";
import { IoIosArrowDown } from "react-icons/io";
import { Formik } from "formik";
import * as Yup from "yup";
import { createCampaignAsyncThunk, getCampaignsCount } from "../../redux/pagesSlices/campaignSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const campaignSchmea = Yup.object({
  campaignName: Yup.string().required("Campaign name is required"),
  content: Yup.string().required("Campaign Description is required"),
  buttonText: Yup.string().required("Button text is required"),
  buttonRoute: Yup.string().required("Button link is required").url("Please enter a valid URL"),
  visibility: Yup.string().required("Visibility is required"),
});

const CompaignModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const handleSaveCampaign = (values)=>{
    console.log("values", values);
    dispatch(createCampaignAsyncThunk({ 
      data: values,
      callback : ()=>{
        toast.success("Campaign created successfully");
        handleClose();
      }
      
    }));
  };
  const loading = useSelector((state)=>state?.campaign?.loadings?.createCampaignAsyncThunk)
  console.log("loading", loading);
  
    
  return (
    <>
      <div className="btn-wrapper new-compaign" onClick={handleShow}>
        <a href="#" className="btn-style transparent-border new-compaign">
          <LuPlus />
          New Campaign
        </a>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className=" custom-modal Compaign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>New Campaign</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <Formik
              initialValues={{
                campaignName: "",
                content: "",
                buttonText: "",
                buttonRoute: "",
                visibility: "",
              }}
              validationSchema={campaignSchmea}
              onSubmit={handleSaveCampaign}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                dirty
              }) => {
                return (
                <>
                  <form action="#" className="form" onSubmit={handleSubmit}>
                    <div className="field-wrapper">
                      <div className="form-group">
                        <label htmlFor="#">Campaign name</label>
                        <input
                          type="text"
                          name="campaignName"
                          value={values.campaignName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control compaign-field"
                          placeholder="Enter your campaign name"
                        />
                        {errors.campaignName && touched.campaignName && (
                          <div className="error">{errors.campaignName}</div>
                        )}
                      </div>
                    </div>
                    <div className="field-wrapper">
                      <div className="form-group">
                        <label htmlFor="#">Campaign Content</label>
                        <input
                          type="text"
                          name="content"
                          value={values.content}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder="Type the content of your ad as it will appear on the website."
                        />
                        {errors.content && touched.content && (
                          <div className="error">{errors.content}</div>
                        )}
                      </div>
                    </div>
                    <div className="field-wrapper">
                      <div className="form-group">
                        <label htmlFor="#">Button text</label>
                        <input
                          type="text"
                          name="buttonText"
                          value={values.buttonText}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control compaign-field"
                          placeholder="eg register"
                        />
                        {errors.buttonText && touched.buttonText && (
                          <div className="error">{errors.buttonText}</div>
                        )}
                      </div>
                    </div>
                    <div className="field-wrapper">
                      <div className="form-group">
                        <label htmlFor="#">Link</label>
                        <input
                          type="text"
                          name="buttonRoute"
                          value={values.buttonRoute}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control compaign-field"
                          placeholder="Enter your email address"
                        />
                        {errors.buttonRoute && touched.buttonRoute && (
                          <div className="error">{errors.buttonRoute}</div>
                        )}
                      </div>
                    </div>
                    <div className="field-wrapper">
                      <div className="form-group">
                        <label htmlFor="#">Visibility</label>
                        {/* <div className='form-select shadow-none select-package css-b62m3t-container mb-4'>
                                        Brands and Creators
                                    </div> */}
                        <Dropdown>
                          <Dropdown.Toggle className="form-control compaign-field">
                            {values.visibility || "Select visibility"}
                            <IoIosArrowDown />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setFieldValue("visibility", "Brands")
                              }
                            >
                              Brands
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                setFieldValue("visibility", "Creators")
                              }
                            >
                              Creators
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                setFieldValue(
                                  "visibility",
                                  "Brands and Creators"
                                )
                              }
                            >
                              Brands and Creators
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {errors.visibility && touched.visibility && (
                          <div className="error">{errors.visibility}</div>
                        )}
                      </div>
                    </div>
                    <div className="compaign-modal-btn">
                      <Button
                        variant="secondary"
                        onClick={handleClose}
                        className="btn-style cancel"
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        //   onClick={handleSubmit}
                        className="btn-style "
                        disabled={!dirty || loading}
                      >
                        {loading ? <Spinner /> : "Save"}
                      </Button>
                    </div>
                  </form>
                </>
              )}}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompaignModal;
