import { APIurls } from "../constants.js";
import apiService from ".";
import multipart from "./multipart.js";

export const ApiRequests = {
  //user Authentication
  login: async (data) => await apiService.post(APIurls.login, data),
  logout: async (data) => await apiService.post(APIurls.logout, data),
  register: async (data) => await apiService.post(APIurls.register, data),
  forgotpassword: async (data) =>
    await apiService.post(APIurls.forgotPassword, data),
  resetPassword: async (data) =>
    await apiService.post(
      APIurls.resetPassword,
      { password: data.password },
      {
        params: {
          token: data.token,
        },
      }
    ),
  updatePassword: async (data) =>
    await apiService.post(APIurls.updatePassword, data),
  authenticate: async () => await apiService.get(APIurls.authenticate),
  refreshToken: async (data) =>
    await apiService.post(APIurls.refreshToken, data),
  //user Authentication

  //user
  getUsers: async (params) => await apiService.get(APIurls.users, { params }),
  getUser: async (id) => await apiService.get(APIurls.users + `/${id}`),
  updateUser: async (data) => await apiService.patch(APIurls.users, data),
  deleteUser: async () => await apiService.delete(APIurls.users),
  createUser: async (data) => await apiService.post(APIurls.users, data),
  getUserSubscriptions: async (params) =>
    await apiService.get(APIurls.users + "/subscriptions", { params }),
  getUserAnalytics: async (params) =>
    await apiService.get(APIurls.users + "/analytics", { params }),
  updateProfile: async (data) => await multipart.patch(APIurls.profile, data),

  // creator
  getCreator: async (id) => await apiService.get(APIurls.creator + `/${id}`),
  getCreatorDetails: async (id) =>
    await apiService.get(APIurls.creator + `/${id}/details`),
  createCreator: async (data) => await multipart.post(APIurls.creator, data),
  createOwner: async (data) =>
    await apiService.post(APIurls.createJob, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access-token")}`,
      },
    }),
  getCreators: async (params) =>
    await apiService.get(APIurls.creator, { params }),
  getCreatorsCount: async (params) =>
    await apiService.get(APIurls.creator + "/count", { params }),
  deleteCreator: async () => await apiService.delete(APIurls.creator),
  reviewCreator: async ({ id, data }) =>
    await apiService.patch(APIurls.creator + "/" + id + "/review", data),
  reloadCreatorStats: async (id) =>
    await apiService.patch(APIurls.creator + "/" + id + "/reload-stats"),
  getCreatorJobTasks: async ({ id, params }) =>
    await apiService.get(APIurls.jobTasks + `/${id}/creator/jobs`, { params }),      
  getCreatorJobAnalytics: async (id) =>
    await apiService.get(APIurls.creator + "/" + id + "/jobs/analytics"),
  updateCreator: async (id, data) =>
    await apiService.patch(APIurls.creator + `/${id}`, data),
  // scrapper
  // getCreatorPerformance: async (data) => await apiService.post(APIurls.creatorPerformance, data, {method:"POST"}),
  getCreatorPerformance: async (data) =>
    await fetch(APIurls.creatorPerformance, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }),
  // scrapper

  sample: async (data) =>
    await multipart.post(APIurls.creator + "/samples", data),
  updateCreatorProfile: async (data) =>
    await apiService.patch(APIurls.creatorProfile, data),
  removeSample: async (key) =>
    await apiService.delete(APIurls.creator + "/samples/" + key),
  sample: async (data, onProgress) =>
    await multipart.post(APIurls.creator + "/samples", data, {
      onUploadProgress: (progressEvent) => {
        // Calculate the upload progress in percentage
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        onProgress(progress);
      },
    }),
  // creator

  // brand
  createBrand: async (data) => await multipart.post(APIurls.brand, data),
  updateBrandProfile: async (data) =>
    await multipart.patch(APIurls.brandProfile, data),
  agreeBrandAgreement: async (data) =>
    await apiService.patch(APIurls.brandAgreement, data),
  updateBrand: async (id, data) =>
    await apiService.patch(APIurls.brand + `/${id}`, data),
  updateBrandImage: async (id, data) =>
    await multipart.patch(APIurls.brand + `/${id}` + "/image", data),
  getBrands: async (params) => await apiService.get(APIurls.brand, { params }),
  getBrandsCountByStatus: async (params) =>
    await apiService.get(APIurls.brand + "/counts-by-status", { params }),
  getMyBrands: async (params) =>
    await apiService.get(APIurls.myBrand, { params }),
  getBrandsAnalytics: async (params) =>
    await apiService.get(APIurls.brand + "/analytics", { params }),
  getBrand: async (id) => await apiService.get(APIurls.brand + `/${id}`),
  // brand

  // admin
  getAdminAnalytics: async (params) =>
    await apiService.get(APIurls.admin + "/analytics", { params }),
  getAdminGraphStats: async (params) =>
    await apiService.get(APIurls.admin + "/graph-stats", { params }),
  getTopCreators: async (params) =>
    await apiService.get(APIurls.admin + "/top-creators", { params }),
  getExportedData:async (params) => 
    await apiService.post(APIurls.admin + "/creators-download", params ),
  
  // admin

  // payment
  createPayment: async (data) => await apiService.post(APIurls.payment, data),
  confirmPayment: async (id) =>
    await apiService.post(APIurls.payment + `/confirm/${id}`),
  disputePayment: async (id, data) =>
    await apiService.post(APIurls.payment + `/dispute/${id}`, data),
  checkout: async (data) =>
    await apiService.post(APIurls.payment + "/checkout", data),
  addPaymentMethod: async (data) =>
    await apiService.post(APIurls.payment + "/payment-method", data),
  getPaymentMethods: async () =>
    await apiService.get(APIurls.payment + "/payment-method"),
  getTransactionWithId: async (id) =>
    await apiService.get(APIurls.payment + "/transaction/" + id),
  // payment
  getApplicationCreatorCounts: async (params) =>
    await apiService.get(APIurls.applicationCreatorCounts, { params }),
  getCardDetail : async(id,params) => await apiService.get(APIurls.payment + `/${id}/card-detail`,{params}),

  // jobs
  getJobs: async (params) => await apiService.get(APIurls.jobs, { params }),
  getAllJobCounts: async (params) =>
    await apiService.get(APIurls.jobs + "/count", { params }),
  getJobCountsByBrandId: async (params) =>
    await apiService.get(APIurls.jobs + "/counts", { params }),
  getJobTasks: async ({ id, params }) =>
    await apiService.get(APIurls.jobTasks + `/${id}/job`, { params }),

  getSingleJobTask : async({id,params}) => await apiService.get(APIurls.jobTasks + `/${id}` , {params}),
  getJobCounts: async (params) =>
    await apiService.get(APIurls.jobCounts, { params }),
  getMyJobs: async (params) => await apiService.get(APIurls.myJobs, { params }),
  getOngoingJobs: async (params) =>
    await apiService.get(APIurls.onGoingJobs, { params }),
  getMyAllJobs: async (params) =>
    await apiService.get(APIurls.myJobs + "/all", { params }),
  getMyJobRelated: async (params) =>
    await apiService.get(APIurls.myJobsRelated, { params }),
  getJobsByBrand: async (id, params) =>
    await apiService.get(APIurls.jobTasks + `/${id}/brand/jobs`, { params }),
  getAvailableJobsByBrand: async (id, params) =>
    await apiService.get(APIurls.brand + `/${id}/jobs/available`, { params }),
  getAvailableJobs: async (params) =>
    await apiService.get(APIurls.availableJobs, { params }),
  getRecommendedJobs: async (params) =>
    await apiService.get(APIurls.recommendedJobs, { params }),
  getHiredJobs: async (params) =>
    await apiService.get(APIurls.hiredJobs, { params }),
  getJob: async (id) => await apiService.get(APIurls.jobs + `/${id}`),
  getJobDetails: async (id) =>
    await apiService.get(APIurls.jobs + `/${id}/details`),
  applyJob: async (id, data) =>
    await apiService.post(APIurls.applyJob, { jobId: id, ...data }),
  createJob: async (data) =>
    await multipart.post(APIurls.jobs, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access-token")}`,
        "Content-Type": "multipart/form-data",
      },
    }),
  updateJob: async ({ id, data }) =>
    await apiService.patch(APIurls.jobs + `/${id}`, data),
  reviewJob: async ({ id, data }) =>
    await apiService.patch(APIurls.jobs + `/${id}/review`, data),
  deleteJob: async (id) => await apiService.delete(APIurls.jobs + `/${id}`),
  // jobs

  // application
  getApplications: async (params) =>
    await apiService.get(APIurls.applications, { params }),
  getMyApplications: async (params) =>
    await apiService.get(APIurls.applications + "/auth", { params }),
  getBrandApplications: async (params) =>
    await apiService.get(
      APIurls.applications + "/creators_applicants_for_brands",
      { params }
    ),
  getBrandApplicationsCount: async (params) =>
    await apiService.get(
      APIurls.applications + "/creators_applicants_for_brands/count",
      { params }
    ),
  getHiredCreators: async (params) =>
    await apiService.get(APIurls.applications + "/hired-creators", { params }),
  getHiredCreatorsOnJob: async ({ id, params }) =>
    await apiService.get(APIurls.jobs + "/" + id + "hired-creators", {
      params,
    }),
  reviewApplication: async ({ id, data }) =>
    await apiService.patch(APIurls.applyJob + `/${id}/review`, data),
  acceptApplication: async ({ id, data }) =>
    await apiService.patch(APIurls.applyJob + `/${id}/accept`, data),
  toggleApplicationFavorite: async ({ id, data }) =>
    await apiService.patch(APIurls.applyJob + `/${id}/favorite`, data),
  toggleApplicationViewed: async ({ id, data }) =>
    await apiService.patch(APIurls.applyJob + `/${id}/viewed`, data),
  getJobApplications: async ({ id, params }) =>
    await apiService.get(APIurls.jobs + "/" + id + "/applications", { params }),
  getJobApplicants: async ({ id, params }) =>
    await apiService.get(APIurls.jobs + "/" + id + "/applicants", { params }),
  getApplication: async (id) =>
    await apiService.get(APIurls.applications + `/${id}`),
  getApplicationDetail: async (id) =>
    await apiService.get(APIurls.applications + `/${id}/detail`),
  createApplication: async (data) =>
    await apiService.post(APIurls.applications, data),
  updateApplication: async ({ id, data }) =>
    await apiService.patch(APIurls.applications + `/${id}`, data),
  deleteApplication: async (id) =>
    await apiService.delete(APIurls.applications + `/${id}`),
  agreeCreatorContractApplication: async ({ id }) =>
    await apiService.patch(
      APIurls.applications + `/${id}/creator-agree-contract`
    ),
  agreeBrandContractApplication: async ({ id }) =>
    await apiService.patch(
      APIurls.applications + `/${id}/brand-agree-contract`
    ),
  // application

  // contracts
  createBrandContract: async () =>
    await apiService.post(APIurls.contracts + "/brand"),
  getContracts: async (params) =>
    await apiService.get(APIurls.contracts, { params }),
  getBrandContracts: async (params) =>
    await apiService.get(
      APIurls.contracts + "/creators_applicants_for_brands",
      { params }
    ),
  getContract: async (id) => await apiService.get(APIurls.contracts + `/${id}`),
  updateContract: async ({ id, data }) =>
    await apiService.patch(APIurls.contracts + `/${id}`, data),
  // contracts

  // chat
  // chat --- Room
  createRoom: async (data) =>
    await apiService.post(APIurls.chat + `/rooms`, data),
  getRooms: async (params) =>
    await apiService.get(APIurls.chat + `/rooms`, { params }),
  getRoom: async (id) => await apiService.get(APIurls.chat + `/rooms/${id}`),
  deleteRoom: async (id) =>
    await apiService.delete(APIurls.chat + `/rooms/${id}`),
  getUserRooms: async () => await apiService.get(APIurls.chat + `/rooms/user`),

  getSystemRoom: async () =>
    await apiService.get(APIurls.chat + `/system/room`),

  // Campaign Module Routes
  createCampaign : async(data) => await apiService.post(APIurls.campaign,data),
  getCampaigns : async(params) => await apiService.get(APIurls.campaign,{params}),
  getCampaign : async(id) => await apiService.get(APIurls.campaign + `/${id}`),
  updateCampaign : async({id,data}) => await apiService.patch(APIurls.campaign + `/${id}`,data),
  getCampaignsCount : async(params) => await apiService.get(APIurls.campaign + `/counts`,{params}),
  updateCampaignById : async(id,data) => await apiService.patch(APIurls.campaign + `/${id}`,{data}),

  // chat --- Message
  getMessages: async (params) => await apiService.get(APIurls.chat, { params }),
  saveMessage: async (data) =>
    await apiService.post(APIurls.chat + `/messages`, data),
  getRoomMessages: async (roomId, params) =>
    await apiService.get(APIurls.chat + `/messages/${roomId}`, { params }),
  readUnreadMessages: async (data) =>
    await apiService.post(APIurls.chat + `/messages/readAll`, data),
  markAllAsRead: async (data) =>
    await apiService.post(APIurls.chat + `/markAllAsRead`, data),
  getUsersWithRoom: async () =>
    await apiService.get(APIurls.chat + `/getusers`),
  deleteMessage: async (id) =>
    await apiService.delete(APIurls.chat + `/message/${id}`),
  sendFile: async (data) =>
    await multipart.post(APIurls.chat + "/upload", data),

  // invite creator
  createInvite: async (data) =>
    await apiService.post(APIurls.invite + `/messages`, data),
  createMultipleInviteFromJob: async (data) =>
    await apiService.post(APIurls.invite + `/multiple-jobs`, data),

  // tiktok
  getAuthDetails: async (data) =>
    await apiService.get(APIurls.tiktokAuthInfo, data),
  getAuthCallback: async (params) =>
    await apiService.get(APIurls.tiktokAuthCallback, {
      params,
    }),
  tiktokCreateTargetCollaborations: async (data) =>
    await apiService.post(APIurls.tiktokTargetCollaborations, data),
  tiktokGetTargetCollaborations: async (params) =>
    await apiService.get(APIurls.tiktokTargetCollaborations, {
      params,
    }),
  tiktokGetTargetCollaboration: async (id) =>
    await apiService.get(APIurls.tiktokTargetCollaborations + "/" + id),
  tiktokGetSampleApplication: async (params) =>
    await apiService.get(APIurls.tiktokGetSampleApplication, {
      params,
    }),
  tiktokGetSampleApplicationFulfillment: async (id, params) =>
    await apiService.get(
      APIurls.tiktokGetSampleApplicationFulfillment + "/" + id,
      {
        params,
      }
    ),
  // analytics
  tiktokGetAnalyticsShopPerformance: async (params) =>
    await apiService.get(APIurls.tiktokGetAnalyticsShopPerformance, {
      params,
    }),
  tiktokGetAnalyticsShopProductPerformance: async (id, params) =>
    await apiService.get(
      APIurls.tiktokGetAnalyticsShopProductPerformance + "/" + id,
      {
        params,
      }
    ),
  tiktokGetAnalyticsShopProductsPerformance: async (params) =>
    await apiService.get(APIurls.tiktokGetAnalyticsShopProductPerformance, {
      params,
    }),
  tiktokGetAnalyticsShopVideosPerformance: async (params) =>
    await apiService.get(APIurls.tiktokGetAnalyticsShopVideoPerformance, {
      params,
    }),
};


