import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";

import suspendIcon from "../assets/images/icons/cancel.svg";
import activateIcon from "../assets/images/icons/tick.svg";
import jobFileIcon from "../assets/images/icons/order-file-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import logotiktokIcon from "../assets/images/icons/logotiktok.svg";
import logofacebookIcon from "../assets/images/icons/logofacebook.svg";
import logoinstagramIcon from "../assets/images/icons/logoinstagram.svg";
import logoytIcon from "../assets/images/icons/logoyt.svg";
import bagIcon from "../assets/images/icons/bag-icon-fill.svg";
import userIcon from "../assets/images/icons/user-fill-icon.svg";
import imgBocIcon from "../assets/images/icons/img-box-icon.svg";
// import chartIconIcon from "../assets/images/icons/chart-icon.svg";
// import serviceIcon from "../assets/images/icons/service-icon.svg";
import gearIcon from "../assets/images/icons/settings-icon.svg";
import barsIcon from "../assets/images/icons/bars-icon.svg";
import JobsImg from "../assets/images/job-img-1.svg";
// import CreatorProfileOverViewTab from "../components/CreatorProfileOverViewTab";
// import CreatorUniquenessComponent from "../components/CreatorUniquenessComponent";
// import CreatorPortfolioTab from "../components/CreatorPortfolioTab";
// import CreatorAnalyticsTab from "../components/CreatorAnalyticsTab";
// import DashboardTrendsChartComponent from "../components/DashboardTrendsChartComponent";
// import TrendChartComponent from "../components/TrendChartComponent";
// import CreatorProfileServiceOfferedTab from "../components/CreatorProfileServiceOfferedTab";
import PreferencesActivityLog from "../components/PreferencesActivityLog";
import PaymentAndSubscriptions from "../components/PaymentAndSubscriptions";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandAsyncThunk,
  getBrandsAnalyticsAsyncThunk,
  updateBrandAsyncThunk,
} from "../redux/pagesSlices/brandSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { getId, returnArray, setValueIfExist } from "../utils/utils";
// import cancelIcon from "../assets/images/icons/cancel.svg";
import {
  getJobCountsByBrandAsyncThunk,
  getJobsByBrandAsyncThunk,
  getOngoingJobsAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import { timeZones } from "../utils/timeZonesList";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { toTitleCase } from "../utils/methods";
import { dateFormat } from "../utils/format";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import BrandJobCardComponent from "../components/brands/BrandJobCardComponent";
import NoCreatorApplicationFoundComponent from "../components/creators/NoCreatorApplicationFoundComponent";

export default function BrandDetailPage() {
  const [creatorProfileTab, setCreatorProfileTab] = useState("jobs");
  const { jobCountsByBrand, jobsByBrand } = useSelector((state) => state.job);
  const [refresh, setRefresh] = useState(false);
  const brand = useSelector((s) => s.brand?.brand);
  const [status, setStatus] = useState("pending");
  const creatorProfileTabHandler = (tab) => {
    setCreatorProfileTab(tab);
  };
  // console.log("brand------------------", brand);
  console.log("jobs by brand", jobsByBrand);
  const { id } = useParams();
  const dispatch = useDispatch();

  const brandJobsParams = {
    ...setValueIfExist({
      status,
      populate: "jobId,creatorId",
    }),
  };

  useEffect(() => {
    if (id) {
      dispatch(getBrandsAnalyticsAsyncThunk(id));
      dispatch(getBrandAsyncThunk(id));
      dispatch(getJobCountsByBrandAsyncThunk({ brandId: id }));
    }
  }, [id, refresh]);

  useEffect(() => {
    if (id) {
      dispatch(getJobsByBrandAsyncThunk({ id, params: brandJobsParams }));
    }
  }, [id, status, refresh]);

  // useEffect(() => {
  //   dispatch(getOngoingJobsAsyncThunk());
  // }, []);
  const socialIcons = {
    Instagram: logoinstagramIcon,
    TikTok: logotiktokIcon,
    YouTube: logoytIcon,
    Facebook: logofacebookIcon,
  };
  const handleChangeBrandStatus = (status) => {
    dispatch(updateBrandAsyncThunk({ id, data: { status } }));
  };

  return (
    <div className="creator-detail-page">
      <div className="container">
        <div className="page-upper-section">
          <div className="row">
            <div className="col-md-4 align-self-center">
              <div className="back-btn-wrapper">
                <Link to="/dashboard/brands">
                  <MdOutlineKeyboardArrowLeft />
                  <strong className="medium-bold">Back to brands</strong>
                </Link>
              </div>
            </div>
            <div className="col-md-8 align-self-center">
              <div className="btn-wrapper">
                {brand?.status == "active" ? (
                  <button
                    className="btn-style blue-border"
                    onClick={() => handleChangeBrandStatus("inactive")}
                  >
                    <img src={suspendIcon} alt="suspendIcon" /> Suspend Brand
                  </button>
                ) : (
                  <button
                    className="btn-style blue-border"
                    onClick={() => handleChangeBrandStatus("active")}
                  >
                    <img src={activateIcon} alt="activateIcon" /> Activate Brand
                  </button>
                )}
                <div className="icon-wrapper">
                  <DropdownButton
                    id={`dropdown-button-drop-down`}
                    drop={"down"}
                    variant="secondary"
                    className="custom-dropdown three-dots-line"
                    title={<BsThreeDots />}
                  >
                    <Dropdown.Item onClick={() => setRefresh(!refresh)}>
                      Refresh
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="creator-profile-info-section">
          <div className="basic-info-wrapper">
            <div className="creator-name-info mb-3">
              <div className="profile-img-wrapper ">
                {brand?.avatar ? (
                  <img
                    src={parseImagePathToURL(brand?.avatar)}
                    alt="avatarIcon"
                    className="profile-img"
                  />
                ) : (
                  <img
                    src={avatarIcon}
                    alt="avatarIcon"
                    className="profile-img"
                  />
                )}
              </div>
              <div className="text-wrapper mt-3">
                <strong className="dark medium d-block">{brand?.name}</strong>
                <strong>
                  <Link to="#" style={{ fontWeight: 500 }}>
                    {brand?.email}
                  </Link>
                </strong>
              </div>
            </div>
            <div className="personal-info-wrapper">
              <div className="text">
                <span>Location</span>
                <strong>United States</strong>
              </div>
              <div className="text">
                <span>Language</span>
                <strong>English </strong>
              </div>
              <div className="text">
                <span>Type</span>
                <strong>{brand?.type}</strong>
              </div>
              <div className="text">
                <span>Member Since</span>
                <strong>{brand?.createdAt.slice(0, 10)}</strong>
              </div>
            </div>
            <div className="social-icons">
              <ul className="social-icons-list">
                {brand?.socials.map((social) => (
                  <li key={social._id}>
                    <Link
                      to={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={socialIcons[social.type] || logofacebookIcon} // Fallback to Facebook icon
                        alt={`${social.type} Icon`}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="creator-profile-main-content-section">
            <div className="tabs-main-wrapper">
              <ul className="tabs-wrapper justify-content-start gap-4">
                <li>
                  <Link
                    to="#"
                    className={`${creatorProfileTab == "jobs" ? "active" : ""}`}
                    onClick={() => creatorProfileTabHandler("jobs")}
                  >
                    <img src={bagIcon} alt="bagIcon" /> Jobs{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={`${
                      creatorProfileTab == "profileOverview" ? "active" : ""
                    }`}
                    onClick={() => creatorProfileTabHandler("profileOverview")}
                  >
                    <img src={userIcon} alt="bagIcon" /> Profile overview
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={`${
                      creatorProfileTab == "payments" ? "active" : ""
                    }`}
                    onClick={() => creatorProfileTabHandler("payments")}
                  >
                    <img src={imgBocIcon} alt="bagIcon" /> Payments &
                    Subscription{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={`${
                      creatorProfileTab == "preferences" ? "active" : ""
                    }`}
                    onClick={() => creatorProfileTabHandler("preferences")}
                  >
                    <img src={gearIcon} alt="bagIcon" /> Preference
                  </Link>
                </li>
              </ul>
            </div>

            <div className="inner-section ">
              {creatorProfileTab === "jobs" && (
                <>
                  <div className="creator-basic-info-cards-main-wrapper">
                    <div className="row">
                      <div className="col-md-3 mb-3 mb-md-0">
                        <div className="info-card-wrapper">
                          <div className="img-wrapper">
                            <img src={barsIcon} alt="barsIcon" />
                          </div>
                          <div className="text-wrapper">
                            <p className="medium-bold">Jobs posted</p>
                            <strong className="large dark">
                              {jobCountsByBrand?.total}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3 mb-md-0">
                        <div className="info-card-wrapper">
                          <div className="img-wrapper">
                            <img src={barsIcon} alt="barsIcon" />
                          </div>
                          <div className="text-wrapper">
                            <p className="medium-bold">Completed</p>
                            <strong className="large dark">
                              {jobCountsByBrand?.completed}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3 mb-md-0">
                        <div className="info-card-wrapper">
                          <div className="img-wrapper">
                            <img src={barsIcon} alt="barsIcon" />
                          </div>
                          <div className="text-wrapper">
                            <p className="medium-bold">Failed</p>
                            <strong className="large dark">
                              {jobCountsByBrand?.rejected}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3 mb-md-0">
                        <div className="info-card-wrapper">
                          <div className="img-wrapper">
                            <img src={barsIcon} alt="barsIcon" />
                          </div>
                          <div className="text-wrapper">
                            <p className="medium-bold">Success rate</p>
                            <strong className="large dark">
                              {(jobCountsByBrand?.successRate || 0).toFixed(2)}%
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="jobs-section shadowed-wrapper">
                    <div className="section-title-with-cards">
                      <strong className="semi_bold dark large">Jobs</strong>
                      <div className="nav-tabs-main-wrapper">
                        <ul className="tabs-wrapper gray_bg">
                          <li className={status === "pending" && `active`}>
                            <a href="#" onClick={() => setStatus("pending")}>
                              Current
                            </a>
                          </li>
                          <li className={status === null && `active`}>
                            <a href="#" onClick={() => setStatus(null)}>
                              History
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="table-wrapper">
                      {jobsByBrand?.totalResults < 1 && (
                        <table className="bottom-bordered">
                          <thead>
                            <tr>
                              <th>
                                <span className="white medium-bold">Name</span>
                              </th>
                              <th>
                                <span className="white medium-bold">
                                  Creator
                                </span>
                              </th>
                              <th>
                                <span className="white medium-bold">
                                  Status
                                </span>
                              </th>
                              <th>
                                <span className="white medium-bold">Start</span>
                              </th>
                              <th>
                                <span className="white medium-bold">End</span>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                        </table>
                      )}
                      <ProviderErrorLoadingPagination
                        reducer="job"
                        action="getJobsByBrandAsyncThunk"
                        asyncThunk={getJobsByBrandAsyncThunk}
                        dataKey="jobsByBrand"
                        Component={BrandJobCardComponent}
                        emptyMessage={
                          <>
                            <NoCreatorApplicationFoundComponent />
                          </>
                        }
                        Parent={({ children }) => (
                          <table className="bottom-bordered">
                            {jobsByBrand?.totalResults > 0 && (
                              <thead>
                                <tr>
                                  <th>
                                    <span className="white medium-bold">
                                      Name
                                    </span>
                                  </th>
                                  <th>
                                    <span className="white medium-bold">
                                      Creator
                                    </span>
                                  </th>
                                  <th>
                                    <span className="white medium-bold">
                                      Status
                                    </span>
                                  </th>
                                  <th>
                                    <span className="white medium-bold">
                                      Start
                                    </span>
                                  </th>
                                  <th>
                                    <span className="white medium-bold">
                                      End
                                    </span>
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                            )}
                            <tbody>{children} </tbody>
                          </table>
                        )}
                        pagination
                      />
                    </div>
                  </div>
                </>
              )}
              {creatorProfileTab === "profileOverview" && (
                <>
                  <div className="creator-unique-wrapper shadowed-wrapper">
                    <div className="creator-uniqueness-main-wrapper">
                      <div className="section-title-with-cards">
                        <strong className=" dark large">
                          What’s Unique About Me
                        </strong>
                      </div>
                      <div className="text-wrapper mb-5">
                        <p>{brand?.description}</p>
                      </div>
                      <div className="creator-categories-list-wrapper">
                        <strong className="large dark d-block mb-3">
                          Categories
                        </strong>
                        <ul className="categories-list">
                          {returnArray(brand?.categories).map((category, i) => (
                            <li key={i}>{category}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {creatorProfileTab === "payments" && (
                <>
                  <div className="payments-and-subscriptions-content shadowed-wrapper">
                    <PaymentAndSubscriptions />
                  </div>
                </>
              )}
              {creatorProfileTab === "preferences" && (
                <>
                  <div className="preferences-content shadowed-wrapper">
                    <div className="preferences-basic-info-wrapper">
                      <div className="section-title-with-cards">
                        <strong className="semi_bold dark large">
                          Preference
                        </strong>
                      </div>
                      <div className="basic-information">
                        <div className="list-wrapper">
                          <p className="medium medium-bold">
                            Preferred Communication
                          </p>
                          <strong className="medium semi_bold dark">
                            {brand?.user?.settings?.emails ? "Email" : ""}
                          </strong>
                        </div>
                        <div className="list-wrapper">
                          <p className="medium medium-bold">
                            Push notification{" "}
                          </p>
                          <strong className="medium semi_bold dark">
                            {brand?.user?.settings?.notifications
                              ? "Enabled"
                              : "Disabled"}
                          </strong>
                        </div>
                        <div className="list-wrapper">
                          <p className="medium medium-bold">Time zone </p>
                          <strong className="medium semi_bold dark">
                            {
                              timeZones?.find(
                                (tz) => tz.value === brand?.user?.timeZone
                              )?.label
                            }
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="activity-log-content shadowed-wrapper">
                    <PreferencesActivityLog />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
