import React, { memo } from "react";
import { Link } from "react-router-dom";
import JobsImg from "../../assets/images/job-img-1.svg";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { toTitleCase } from "../../utils/methods";
import { dateFormat } from "../../utils/format";
import avatarIcon from "../../assets/images/icons/avatar-icon.svg";

const BrandJobCardComponent = memo(({ item = {} }) => {
  return (
    <tr>
      <td>
        <div className="main-wrapper">
          <div className="img-wrapper">
            <Link to="/dashboard/brand-detail">
              <img
                src={
                  item?.jobId?.image?.length > 0
                    ? parseImagePathToURL(item?.jobId?.image[0])
                    : JobsImg
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong className="dark semi_bold">
              <Link to="/dashboard/brand-detail">{item?.jobId?.jobTitle}</Link>
            </strong>
          </div>
        </div>
      </td>
      <td>
        <div className="main-wrapper ex-small">
          <div className="img-wrapper">
            <Link to="/dashboard/job-detail">
              <img
                src={
                  item?.creatorId?.avatar
                    ? parseImagePathToURL(item?.creatorId?.avatar)
                    : avatarIcon
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong className="dark semi_bold">
              <Link to="/dashboard/creator-detail">
                {item?.creatorId?.name || item?.creatorId?.userName}
              </Link>
            </strong>
          </div>
        </div>
      </td>
      <td>
        <p className={`dark status-text ${item?.status}`}>
          {toTitleCase(item?.status)}
        </p>
      </td>
      <td>
        <p className="dark">{dateFormat(item?.createdAt)}</p>
      </td>
      <td>
        <p className="dark">{dateFormat(item?.endTime)}</p>
      </td>
    </tr>
  );
});

export default BrandJobCardComponent;
