import React, { useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";
import { useSelector } from "react-redux";

const filterLabels = {
  "createdAt:desc": "Newest First",
  "createdAt:asc": "Oldest First",
  "name:asc": "A-Z",
  "name:desc": "Z-A",
};
export default function TabsAndFilterBarCreator({
  activeTab,
  setActiveTab,
  search,
  setSearch,
  sortBy,
  setSortBy,
  largeContainer,
}) {
  const { creatorsCount } = useSelector((s) => s.creator);
  return (
    <div className="tabs-and-filter-bar-main-wrapper">
      <div className={`container ex-large ${largeContainer ? "large" : ""}`}>
        <div className="row">
          <div className="col-md-7 align-self-center">
            <div className="nav-tabs-main-wrapper">
              <ul className="tabs-wrapper gray_bg d-inline-block">
                <li className={activeTab === "all" && `active`}>
                  <a href="#" onClick={() => setActiveTab("all")}>
                    All ({creatorsCount?.total})
                  </a>
                </li>
                <li className={activeTab === "active" && `active`}>
                  <a href="#" onClick={() => setActiveTab("active")}>
                    Active ({creatorsCount?.active})
                  </a>
                </li>
                <li className={activeTab === "inactive" && `active`}>
                  <a href="#" onClick={() => setActiveTab("inactive")}>
                    Inactive ({creatorsCount?.inactive})
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 align-self-center">
            <div className="right-menu-wrapper cusrtom-search-bar-wrapper">
              <div className="search-bar-wrapper">
                <img
                  src={SearchIcon}
                  alt="SearchIcon"
                  className="search-icon"
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Creators"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="filter-and-view-wrapper">
                <div className="sort-by-wrapper">
                  <strong>Sort by: </strong>
                  <DropdownButton
                    onSelect={setSortBy}
                    id={`dropdown-button-drop-down`}
                    drop={"down"}
                    variant="secondary"
                    title={filterLabels[sortBy]}
                  >
                    <Dropdown.Item eventKey="createdAt:desc">
                      Newest First
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="createdAt:asc">
                      Oldest First
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="name:asc">A-Z</Dropdown.Item>
                    <Dropdown.Item eventKey="name:desc">Z-A</Dropdown.Item>
                  </DropdownButton>
                  {/* <img src={filterIcon} alt="filterIcon" className="filter-icon" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
