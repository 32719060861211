import React, { useEffect, useState } from "react";

import userIcon from "../assets/images/icons/user-light-icon.svg";
import teamIcon from "../assets/images/icons/team-icon.svg";
import fileIcon from "../assets/images/icons/file-icon-light.svg";
import logoutIcon from "../assets/images/icons/logout-light-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import SettingTeamsComponent from "../components/SettingTeamsComponent";
import EditUserRole from "../components/modals/EditUserRole";
import AddUserModal from "../components/modals/AddUserModal";
import SettingAuditLogComponent from "../components/SettingAuditLogComponent";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { parseImagePathToURL } from "../utils/globalFunctions";
import * as Yup from "yup";
import {
  updateProfile,
  userLogoutAsyncThunk,
} from "../redux/pagesSlices/authSlice";
import { Spinner } from "react-bootstrap";
import { callback } from "chart.js/helpers";
// --------------------------- Formik validationSchema ---------------------------
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  avatar: Yup.mixed()
    .test("fileSize", "File too large", (value) => {
      if (!value) return true;
      return value.size <= 10485760;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (!value) return true;
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    }),
});
// --------------------------- Component  starts here ---------------------------
export default function Settings() {
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [settingsTab, setSettingsTab] = useState("profile");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const updateProfileLoading = useSelector(
    (state) => state.auth.loadings.updateProfile
  );

  const settingsTabHandler = (tab) => {
    setSettingsTab(tab);
  };
  const editRoleModalOpenHandler = () => {
    setEditRoleModal(true);
  };
  const editRoleModalCloseHandler = () => {
    setEditRoleModal(false);
  };
  const addUserModalOpenHandler = () => {
    setAddUserModal(true);
  };
  const addUserModalCloseHandler = () => {
    setAddUserModal(false);
  };
  const handleProfileUpdate = (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    if (values?.avatar) {
      formData.append("avatar", values.avatar);
    }
    dispatch(
      updateProfile({
        data: formData,
        callBack: () => {
          resetForm();
        },
      })
    );
  };
  const handleLogout = () => {
    dispatch(userLogoutAsyncThunk());
  };
  return (
    <div className="settings-page">
      <div className="container">
        <div className="title mb-4">
          <h3 className="dark large">Settings</h3>
        </div>
        <div className="settings-inner-section">
          <div className="row">
            <div className="col-md-4">
              <div className="side-tabs-main-wrapper">
                <div className="side-tabs">
                  <ul className="tabs-list">
                    <li>
                      <a
                        href="#"
                        onClick={() => settingsTabHandler("profile")}
                        className={`${
                          settingsTab === "profile" ? "active" : ""
                        }`}
                      >
                        <img src={userIcon} alt="userIcon" /> Profile{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => settingsTabHandler("teams")}
                        className={`${settingsTab === "teams" ? "active" : ""}`}
                      >
                        <img src={teamIcon} alt="teamIcon" /> Teams{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => settingsTabHandler("auditLog")}
                        className={`${
                          settingsTab === "auditLog" ? "active" : ""
                        }`}
                      >
                        <img src={fileIcon} alt="fileIcon" /> Audit Log{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div onClick={handleLogout} className="logout-btns">
                  <ul className="tabs-list">
                    <li>
                      <a href="#">
                        <img src={logoutIcon} alt="logoutIcon" /> Log out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="setting-tabs-main-content-wrapper">
                <div className="tab-title-wrapper">
                  <strong className="large dark">Account</strong>
                  <div></div>
                  {/* <button className="btn-style" onClick={addUserModalOpenHandler}> Add User</button> */}
                </div>
                <div className="inner-body-wrapper">
                  {settingsTab === "profile" && (
                    <div className="account-tab-content-wrapper">
                      <Formik
                        initialValues={{
                          firstName: user?.firstName || "",
                          lastName: user?.lastName || "",
                          email: user?.email || "",
                          avatar: "",
                        }}
                        onSubmit={handleProfileUpdate}
                        validationSchema={validationSchema}
                        enableReinitialize
                      >
                        {({ handleSubmit, values, setFieldValue, dirty }) => {
                          return (
                            <Form onSubmit={handleSubmit} className="form">
                              {/* <div className="profile-pic-wrapper">
                                    <label htmlFor="#">Profile Photo</label>
                                    <div className="profile-image">
                                      <img
                                        src={
                                          values?.avatar
                                            ? parseImagePathToURL(values?.avatar)
                                            : avatarIcon
                                        }
                                        alt="avatarIcon"
                                        className="avatar-pic"
                                      />
                                    </div>
                                  </div> */}
                              <div className="profile-pic-wrapper">
                                <label htmlFor="profilePhoto">
                                  Profile Photo
                                </label>
                                <div className="profile-image">
                                  <img
                                    src={
                                      values?.avatar instanceof File
                                        ? URL.createObjectURL(values?.avatar)
                                        : parseImagePathToURL(user?.avatar) ||
                                          avatarIcon
                                    }
                                    alt="avatarIcon"
                                    className="avatar-pic"
                                    onClick={() =>
                                      document
                                        .getElementById("fileInput")
                                        .click()
                                    }
                                    onError={(e) => {
                                      console.error("Image failed to load:", e);
                                      e.target.src = avatarIcon; // Fallback to default icon
                                    }}
                                  />
                                </div>
                                <input
                                  id="fileInput"
                                  type="file"
                                  style={{ display: "none" }}
                                  accept="image/*"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "avatar",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                                <span className="error-msg">
                                  <ErrorMessage name="avatar" />
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="field-wrapper">
                                    <div className="form-group">
                                      <label htmlFor="firstName">
                                        First name
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        //   onChange={handleChange}
                                        //   value={values.firstName}
                                        placeholder="John"
                                      />
                                    </div>
                                    <span className="error-msg">
                                      <ErrorMessage name="firstName" />
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="field-wrapper">
                                    <div className="form-group">
                                      <label htmlFor="lastName">
                                        Last name
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        //   onChange={handleChange}
                                        //   value={values.lastName}
                                        placeholder="Doe"
                                      />
                                    </div>
                                    <span className="error-msg">
                                      <ErrorMessage name="lastName" />
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="field-wrapper">
                                    <div className="form-group">
                                      <label htmlFor="email">Email</label>
                                      <Field
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        //   onChange={handleChange}
                                        //   value={values.email}
                                        placeholder="email@example.com"
                                        disabled
                                      />
                                    </div>
                                    <span className="error-msg">
                                      <ErrorMessage name="email" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="submit-btn-wrapper">
                                <button
                                  disabled={!dirty}
                                  type="submit"
                                  className="btn-style blue-btn"
                                >
                                  {updateProfileLoading ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  )}

                  {settingsTab === "teams" && (
                    <div className="teams-tab">
                      <SettingTeamsComponent
                        editRoleModalOpenHandler={editRoleModalOpenHandler}
                      />
                    </div>
                  )}
                  {settingsTab === "auditLog" && (
                    <div className="audit-log-tab">
                      <SettingAuditLogComponent />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditUserRole
        editRoleModalCloseHandler={editRoleModalCloseHandler}
        editRoleModal={editRoleModal}
      />
      <AddUserModal
        addUserModalCloseHandler={addUserModalCloseHandler}
        addUserModal={addUserModal}
      />
    </div>
  );
}
