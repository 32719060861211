import React from "react";
import { returnArray } from "../utils/utils";

export default function CreatorUniquenessComponent({ creator }) {
  return (
    <div className="creator-uniqueness-main-wrapper">
      <div className="section-title-with-cards">
        <strong className=" dark large">What’s Unique About Me</strong>
      </div>
      <div className="text-wrapper mb-5">
        <p>{creator?.bio}</p>
      </div>
      <div className="creator-categories-list-wrapper">
        <strong className="large dark d-block mb-3">Categories</strong>
        <ul className="categories-list">
          {returnArray(creator?.categories).map((category, i) => (
            <li key={i}>{category}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
