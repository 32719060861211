import React from "react";
import settingIcon from "../assets/images/icons/settings-light-icon.svg";
import xIcon from "../assets/images/icons/x-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import NoNotificationComponent from "./NoNotificationComponent";
import { Spinner } from "react-bootstrap";

export default function NotificationComponent({
  hideNotificationsHandler,
  showNotification
}) {

  return (
    <div
      className={`notification-main-wrapper ${showNotification ? "show" : ""}`}
    >
      <div className="title-wrapper">
        <strong className="dark large medium-bold">Notifications</strong>
        <ul className="list">
          <li>
            <a href="#">
              <img src={settingIcon} alt="settingIcon" />
            </a>
          </li>
          <li>
            <a href="#" onClick={hideNotificationsHandler}>
              <img src={xIcon} alt="xIcon" />
            </a>
          </li>
        </ul>
      </div>
      <div className="notification-list-wrapper">
        <div className="notifications-wrapper">
          <div className="inner-wrapper">
            <div className="notification-list-title-wrapper ">
              <span>TODAY</span>
              <div className="right-menu-wrapper">
                <span className="small">Show only unread</span>
                <div className="custom-toggle-swit  ch-wrapper">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
                <a href="#" className="blue">
                  Mark all as read
                </a>
              </div>
            </div>
            <div className="all-listings-wrapper">
              <div
                className={`notification-wrapper notifications" `}
              >
                <div className="avatar-icon">
                  <a href="#">
                    <img
                      src={
                        avatarIcon
                      }
                      alt="avatarIcon"
                    />
                  </a>
                </div>
                <div className="text-wrapper">
                  <strong className="dark">
                    <a href="#">
                      2:39
                    </a>
                  </strong>
                  <span className="time">
                    19-19-2020
                  </span>
                </div>
              </div>
            </div>
            <div className="notification-list-title-wrapper ">
              <span>OLDER</span>
            </div>
            <div className="all-listings-wrapper">
              <div
                className={`notification-wrapper  notifications`}
              >
                <div className="avatar-icon">
                  <a href="#">
                    <img
                      src={
                        avatarIcon
                      }
                      alt="avatarIcon"
                    />
                  </a>
                </div>
                <div className="text-wrapper">
                  <strong className="dark">
                    <a href="#">
                      {'Mirza Ahsan Mughal Badshah'}
                    </a>
                  </strong>
                  <span className="time">
                    Sender Message
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-all-notifications-btn-wrapper">
          <button className="view-all-notification-btn">
            View all notifications
          </button>
        </div>
        <>
          <NoNotificationComponent />
        </>
      </div>
    </div>
  );
}
