import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";
import { useDispatch, useSelector } from "react-redux";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import CompaignModal from "../components/modals/CompaignModal";
import { Link } from "react-router-dom";
import {
  getCampaignsAsyncThunk,
  getCampaignsCountAsyncThunk,
  updateCampaignByIdAsyncThunk,
} from "../redux/pagesSlices/campaignSlice";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import NoCreatorFoundComponent from "../components/creators/NoCreatorFoundComponent";
import { setValueIfExist } from "../utils/utils";
import { DropdownItem, DropdownMenu } from "react-bootstrap";
import { toast } from "react-toastify";

const Compaignpage = () => {
  const [activeTab, setActiveTab] = useState("all");
  const { creatorsCount } = useSelector((s) => s.creator);
  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [campaignId,setCampaignId] = useState("")
  console.log("campaignId", campaignId);
  
  const filterLabels = {
    "createdAt:desc": "Newest First",
    "createdAt:asc": "Oldest First",
    "campaignName:asc": "A-Z",
    "campaignName:desc": "Z-A",
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      ...setValueIfExist({
        campaignName: search,
        sortBy,
      }),
    };
    if (activeTab !== "all") {
      params.status = activeTab;
    }
    setTimeout(() => {
      dispatch(getCampaignsAsyncThunk(params));
    }, 500);
  }, [dispatch, search, activeTab, sortBy]);
  const campaigns = useSelector((state) => state.campaign.campaigns);

  useEffect(() => {
    dispatch(getCampaignsCountAsyncThunk({}));
  }, [dispatch]);
  const campaignsCount = useSelector((s) => s.campaign.campaignCount);
  const handleUpdateCampaignStatus = (campaignId,status) => {
    console.log("campaignId", campaignId,status);
    
    dispatch(
      updateCampaignByIdAsyncThunk({
        id: campaignId,
        data: {
          status: status === "Active" ? "Inactive" : "Active",
        },
        callback : () => {
          toast.success("Campaign updated successfully")
          dispatch(getCampaignsAsyncThunk({}))
          dispatch(getCampaignsCountAsyncThunk({}))
        }
      })
    )
  }
  return (
    <div className="compaign-page">
      <div className="m-0">
        <div className="jobs-page">
          <div className="page-title-header-component">
            <div className="container ex-large ">
              <div className="row">
                <div className="col-md-4 align-self-center">
                  <div className="inner-page-title-wrapper">
                    <h2>Campaign</h2>
                  </div>
                </div>
                <div className="col-md-8 align-self-center">
                  <CompaignModal />
                  {/* <div className='btn-wrapper new-compaign'>
                                        <a href="#" className='btn-style transparent-border new-compaign'>
                                        <LuPlus />

                                            New Compaign
                                        </a>
                                    </div> */}
                </div>
                <div className="tabs-and-filter-bar-main-wrapper"></div>
                <div className="col-md-7 align-self-center">
                  <div className="nav-tabs-main-wrapper">
                    <ul className="tabs-wrapper gray_bg d-inline-block">
                      <li className={activeTab === "all" && `active`}>
                        <a
                          className="semi_bold"
                          href="#"
                          onClick={() => setActiveTab("all")}
                        >
                          All ({campaignsCount?.total})
                        </a>
                      </li>
                      <li className={activeTab === "active" && `active`}>
                        <a
                          className="semi_bold"
                          href="#"
                          onClick={() => setActiveTab("active")}
                        >
                          Active ({campaignsCount?.active})
                        </a>
                      </li>
                      <li className={activeTab === "inactive" && `active`}>
                        <a
                          className="semi_bold"
                          href="#"
                          onClick={() => setActiveTab("inactive")}
                        >
                          Inactive ({campaignsCount?.inactive})
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5 align-self-center">
                  <div className="right-menu-wrapper cusrtom-search-bar-wrapper">
                    <div className="search-bar-wrapper">
                      <img
                        src={SearchIcon}
                        alt="SearchIcon"
                        className="search-icon"
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Campaigns"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>

                    <div className="filter-and-view-wrapper">
                      <div className="sort-by-wrapper">
                        <strong>Sort by: </strong>
                        <DropdownButton
                          id={`dropdown-button-drop-down`}
                          drop={"down"}
                          variant="secondary"
                          onSelect={setSortBy}
                          title={filterLabels[sortBy]}
                        >
                          <Dropdown.Item eventKey="createdAt:desc">
                            Newest First
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="createdAt:asc">
                            Oldest First
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="campaignName:asc">
                            A-Z
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="campaignName:desc">
                            Z-A
                          </Dropdown.Item>
                        </DropdownButton>
                        {/* <img src={filterIcon} alt="filterIcon" className="filter-icon" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="job-listings-section">
        <div className="container ex-large">
          <div className="listing-main-wrapper">
            <div className="table-wrapper">
              {campaigns?.totalResults < 1 && (
                <table className="gapped">
                  <thead>
                    <tr>
                      <th>
                        <span className="white medium-bold">Name</span>
                      </th>
                      <th>
                        <span className="white medium-bold">Visibility</span>
                      </th>
                      <th>
                        <span className="white medium-bold">Date</span>
                      </th>
                      <th>
                        <span className="white medium-bold">Status</span>
                      </th>
                      <th>
                        <span className="white medium-bold">Performance</span>
                      </th>
                    </tr>
                  </thead>
                </table>
              )}
              <ProviderErrorLoadingPagination
                reducer={"campaign"}
                action={"getCampaignsAsyncThunk"}
                asyncThunk={getCampaignsAsyncThunk}
                dataKey={"campaigns"}
                Component={CampaignRowCard}
                emptyMessage={
                  <>
                    <NoCreatorFoundComponent />
                  </>
                }
                componentProps={{handleUpdateCampaignStatus}}
                // pagination={false}
                Parent={({ children }) => (
                  <table className="gapped">
                    {campaigns?.totalResults > 0 && (
                      <thead>
                        <tr>
                          <th>
                            <span className="white medium-bold">Name</span>
                          </th>
                          <th>
                            <span className="white medium-bold">
                              Visibility
                            </span>
                          </th>
                          <th>
                            <span className="white medium-bold">Date</span>
                          </th>
                          <th>
                            <span className="white medium-bold">Status</span>
                          </th>
                          <th>
                            <span className="white medium-bold">
                              Performance
                            </span>
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>{children}</tbody>
                  </table>
                )}
                itemKey="item"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compaignpage;

function CampaignRowCard({ item,handleUpdateCampaignStatus }) {
  return (
    <tr>
      <td>
        <strong className="dark semi_bold">{item?.campaignName}</strong>
      </td>
      <td>
        <p className="dark">{item?.visibility}</p>
      </td>
      <td>
        <p className="dark">{item?.createdAt?.slice(0, 10)}</p>
      </td>

      <td>
        <p className="dark status-text active">{item?.status}</p>
      </td>
      <td>
        <div className="compaign-perfomance">
          <div className="action-btn-wrapper action-perfomance">
            <div>
              <p className="medium">{item?.views}</p>
              <span className="small medium-bold">Views</span>
            </div>
            <div>
              <p className="medium">{item?.clicks}</p>
              <span className="small medium-bold">Clicks</span>
            </div>
            <div>
              <p className="medium">72%</p>
              <span className="small medium-bold">Converted</span>
            </div>
          </div>
          <div className="action-btn-wrapper">
            <Link to={`/dashboard/job-detail`}>
              <div className="icon-wrapper rounded medium">
                <img src={eyeIcon} alt="eyeIcon" />
              </div>
            </Link>
            <DropdownButton
              id={`dropdown-button-drop-down`}
              drop={"down"}
              variant="secondary"
              title={<HiOutlineDotsVertical />}
              className="campaign-dropdown custom-dropdown three-dots-line"
            >
              <Dropdown.Item onClick={() => handleUpdateCampaignStatus(item?.id,item?.status)}> {item?.status === "Active" ? "Deactivate" : "Activate"} Campaign</Dropdown.Item>
            </DropdownButton>
          </div>

          {/* <div className='action-btn-wrapper action-icon'>
                                                    <button className='icon-wrapper rounded medium'>
                                                        <img src={eyeIcon} alt="eyeIcon" />
                                                    </button>
                                                    <button className='custom-dropdown three-dots-line dropdown'>
                                                        <HiOutlineDotsVertical />
                                                    </button>
                                                </div> */}
        </div>
      </td>
    </tr>
  );
}
