import React, { memo, useEffect, useRef, useState } from "react";
import { Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import JobsImg from "../../assets/images/job-img-1.svg";
import eye from "../../assets/images/icons/eye.svg";
import eyeIcon from "../../assets/images/icons/eye-icon.svg";
import shopIcon from "../../assets/images/icons/shop-icon.svg";
import targetIcon from "../../assets/images/icons/target-icon.svg";
import refreshIcon from "../../assets/images/icons/refresh-icon-blue.svg";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { toTitleCase } from "../../utils/methods";
import { dateFormat } from "../../utils/format";
import { getId } from "../../utils/utils";
import { confirmPaymentAsyncThunk } from "../../redux/pagesSlices/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import ViewProfileDetailModal from "../modals/ViewProfileDetailModal";
import { getJobTasksAsyncThunk } from "../../redux/pagesSlices/applicationSlice";
import DisputeModal from "../modals/DisputeModal";

const JobTasksCardComponent = memo(({ item = {}, jobTasksParams, jobId,setShowDetailModel,showDetailModel,selectedItemId,setSelectedItemId }) => {
  const dispatch = useDispatch();
  // console.log("asad", jobId);
  const [showModel, setShowModel] = useState(false);
  const [showDisputeModel, setShowDisputeModel] = useState(false);
  const { loadings } = useSelector((state) => state.payment);
  const modalRef = useRef();

  // useEffect(() => {
  //   if (showDetailModel) {
  //     // Add any other side effects related to the modal here
  //   }
  // }, [showDetailModel]);

  const handleShowDetailModel = (id) => {
    setSelectedItemId(id);
    setShowDetailModel(true);
  };
  const handleHideDetailModel = () => {
    setSelectedItemId(null);
    setShowDetailModel(false);
  };

  const handleConfirmPayment = (id) => {
    dispatch(
      confirmPaymentAsyncThunk({
        id,
        callback: () => {
          dispatch(
            getJobTasksAsyncThunk({
              id: jobId,
              params: jobTasksParams,
            })
          );
        },
      })
    );
  };

  const creatorDetails = item?.creatorId;
  const applicationDetails = item?.applicationId;

  return (
    <>
      <tr>
        <td>
          <div className="main-wrapper user-info">
            <div className="img-wrapper">
              <Link to={`/dashboard/creator-detail/${getId(creatorDetails)}`}>
                <img
                  src={
                    creatorDetails?.avatar
                      ? parseImagePathToURL(creatorDetails?.avatar)
                      : JobsImg
                  }
                  alt="JobsImg"
                />
              </Link>
            </div>
            <div className="text-wrapper">
              <strong className="dark semi_bold">
                <Link to={`/dashboard/creator-detail/${getId(creatorDetails)}`}>
                  {creatorDetails?.name || creatorDetails?.userName}
                </Link>
              </strong>
              <span className="username">
                @{creatorDetails?.tikTokUserName}
              </span>
            </div>
          </div>
        </td>
        <td>
          <p className="dark">
            {item?.jobProgressInfo?.video_count || 0}/
            {applicationDetails?.videoPrice?.count}
          </p>
        </td>
        <td>
          <p className="dark">
            {item?.jobProgressInfo?.live_count || 0}/
            {applicationDetails?.livePrice?.count}
          </p>
        </td>
        <td>
          <p className="dark">{dateFormat(item?.createdAt)}</p>{" "}
        </td>
        <td>
          <p className={`dark status-text ${item?.status}`}>
            {toTitleCase(item?.status)}
          </p>
        </td>
        <td>
          <div className="action-btn-wrapper">
            <Link
              as={`button`}
              onClick={(e) => {
                e.stopPropagation();
                handleShowDetailModel(item.id);
              }}
            >
              <div className="icon-wrapper rounded medium">
                <img src={eyeIcon} alt="eyeIcon" />
              </div>
            </Link>

            <DropdownButton
              id={`dropdown-button-drop-down`}
              drop={"down"}
              variant="secondary"
              className="custom-dropdown three-dots-line"
              title={<PiDotsThreeOutlineVerticalFill />}
            >
              <div className="title">
                <strong className="dark">Action</strong>
              </div>
              {item?.paymentInfo?.isPaid &&
                !item?.paymentInfo?.hasSent &&
                item?.paymentInfo?.status === "paid" && (
                  <>
                    <Dropdown.Item
                      eventKey="1"
                      disabled={loadings.confirmPaymentAsyncThunk}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleConfirmPayment(getId(item));
                      }}
                    >
                      {loadings.confirmPaymentAsyncThunk ? (
                        <Spinner size="sm" />
                      ) : (
                        "Confirm Payment"
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      disabled={loadings.disputePaymentAsyncThunk}
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowDisputeModel(true);
                        // handleDisputePayment(getId(item));
                      }}
                    >
                      {loadings.disputePaymentAsyncThunk ? (
                        <Spinner size="sm" />
                      ) : (
                        "Dispute Payment"
                      )}
                    </Dropdown.Item>
                  </>
                )}
            </DropdownButton>
          </div>
        </td>
      </tr>
      {showDisputeModel && (
        <DisputeModal
          open={showDisputeModel}
          task={item}
          jobId={jobId}
          jobTasksParams={jobTasksParams}
          handleCloseModel={() => setShowDisputeModel(false)}
        />
      )}
      
    </>
  );
});

export default JobTasksCardComponent;
