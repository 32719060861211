import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  disputePaymentAsyncThunk,
  getTransactionDetailsAsyncThunk,
} from "../../redux/pagesSlices/paymentSlice";
import { getJobTasksAsyncThunk } from "../../redux/pagesSlices/applicationSlice";
import { getId } from "../../utils/utils";
import { Spinner } from "react-bootstrap";

const validationSchema = Yup.object({
  creator: Yup.number()
    .min(0, "Percentage cannot be less than 0")
    .max(100, "Percentage cannot be more than 100")
    .required("Creator's percentage is required"),
  brand: Yup.number()
    .min(0, "Percentage cannot be less than 0")
    .max(100, "Percentage cannot be more than 100")
    .required("Brand's percentage is required"),
});

export default function DisputeModal({
  handleCloseModel,
  open,
  task,
  jobId,
  jobTasksParams,
}) {
  const [creatorAmount, setCreatorAmount] = useState(0);
  const [brandAmount, setBrandAmount] = useState(0);
  const { loadings, transaction } = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  console.log("task", task);
  console.log("transaction", transaction);

  useEffect(() => {
    if (task?.paymentInfo.transactionId) {
      dispatch(
        getTransactionDetailsAsyncThunk({
          id: task?.paymentInfo.transactionId,
        })
      );
    }
  }, [task?.paymentInfo.transactionId]);

  useEffect(() => {
    if (transaction?.amount_received) {
      const totalAmountInCents = transaction.amount_received;

      const handlePercentageChange = (creator, brand) => {
        const creatorTransfer = (totalAmountInCents * creator) / 100;
        const brandTransfer = (totalAmountInCents * brand) / 100;
        setCreatorAmount(creatorTransfer);
        setBrandAmount(brandTransfer);
      };

      handlePercentageChange(100, 0); // default values for creator and brand
    }
  }, [transaction]);

  const handleSubmit = (values) => {
    console.log("Submitted Values:", values);

    dispatch(
      disputePaymentAsyncThunk({
        id: getId(task),
        data: values,
        callback: () => {
          handleCloseModel();
          dispatch(
            getJobTasksAsyncThunk({
              id: jobId,
              params: jobTasksParams,
            })
          );
        },
      })
    );
  };

  const validateTotal = (values) => {
    const errors = {};
    const total = Number(values.creator) + Number(values.brand);
    console.log("total", total);
    if (total > 100) {
      errors.creator =
        "Total percentage of Creator and Brand cannot exceed 100";
      errors.brand = "Total percentage of Creator and Brand cannot exceed 100";
    }
    return errors;
  };

  return (
    <div className="add-user-modal-wrapper">
      <Modal
        show={open}
        onHide={handleCloseModel}
        className="custom-modal edit-time-modal"
        centered
      >
        <Modal.Header>
          <strong className="dark large">Select dispute amount</strong>
          <Button
            variant="close"
            onClick={handleCloseModel}
            aria-label="Close"
            style={{ marginLeft: 0 }}
            className="cancel-btn "
          ></Button>
        </Modal.Header>

        <Modal.Body>
          <div className="inner-body-wrapper">
            <Formik
              initialValues={{ creator: 100, brand: 0 }}
              validationSchema={validationSchema}
              validate={validateTotal}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form className="form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="field-wrapper">
                        <div className="form-group">
                          <label htmlFor="creator">
                            Creator{" "}
                            <small className="text-muted">
                              (Enter amount in percentage)
                            </small>
                          </label>
                          <Field
                            type="number"
                            name="creator"
                            className="form-control"
                            placeholder="Enter amount in percentage"
                            onChange={(e) => {
                              const creator = e.target.value;
                              setFieldValue("creator", creator);
                              const totalAmountInCents =
                                transaction.amount_received;
                              const creatorTransfer =
                                (totalAmountInCents * creator) / 100;
                              setCreatorAmount(creatorTransfer);
                            }}
                          />
                          <ErrorMessage
                            name="creator"
                            component="div"
                            className="text-danger"
                          />
                          <div className="amount-display">
                            Amount to be transferred to Creator:{" "}
                            {creatorAmount / 100} USD
                          </div>

                          <label htmlFor="brand" className="mt-4">
                            Brand{" "}
                            <small className="text-muted">
                              (Enter amount in percentage)
                            </small>
                          </label>
                          <Field
                            type="number"
                            name="brand"
                            className="form-control"
                            placeholder="Enter amount in percentage"
                            onChange={(e) => {
                              const brand = e.target.value;
                              setFieldValue("brand", brand);
                              const totalAmountInCents =
                                transaction.amount_received;
                              const brandTransfer =
                                (totalAmountInCents * brand) / 100;
                              setBrandAmount(brandTransfer);
                            }}
                          />
                          <ErrorMessage
                            name="brand"
                            component="div"
                            className="text-danger"
                          />
                          <div className="amount-display">
                            Amount to be transferred to Brand:{" "}
                            {brandAmount / 100} USD
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-end">
                    <a
                      href="#"
                      className="btn-style transparent auto_width"
                      onClick={handleCloseModel}
                    >
                      Cancel
                    </a>
                    <button
                      type="submit"
                      className="btn-style auto_width"
                      disabled={loadings?.disputePaymentAsyncThunk}
                    >
                      {loadings?.disputePaymentAsyncThunk ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
