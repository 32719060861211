import React, { useState } from "react";
import { Spinner } from "react-bootstrap"
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getExportedDataAsyncThunk } from "../../redux/pagesSlices/adminSlice";


export default function ExportDataModal({ exportDataModalClose, exportDataModal }) {
    const [fileType, setFileType] = useState('csv')
    const [filter, setFilter] = useState(false)
    const dispatch = useDispatch()
    const { loadings } = useSelector((state) => state.admin)
    console.log("Loadings", loadings.getExportedDataAsyncThunk)

    const handleExportedData = (e) => {
        e.preventDefault()
        console.log(fileType)
        dispatch(getExportedDataAsyncThunk({
            fileType, filter, callback: () => {
                exportDataModalClose()
            }
        }))
    }

    return (
        <div className="edit-user-role-modal-wrapper">
            <Modal
                show={exportDataModal}
                onHide={exportDataModalClose}
                className="custom-modal export-data-modal"
                centered
            >
                <Modal.Header>

                    <strong className="dark large">Export data</strong>
                    <Button
                        variant="close"
                        onClick={exportDataModalClose}
                        aria-label="Close"
                        style={{ marginLeft: 0 }}
                        className="cancel-btn "
                    ></Button>
                </Modal.Header>

                <Modal.Body>
                    <div className="inner-body-wrapper">
                        <form action="#" className="form">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="field-wrapper">
                                        <span>EXPORT</span>
                                        <div className="radio-btns">
                                            <div className="form-group">
                                                <input type="radio" id="current-result" name="export" value="Current filter result" onChange={() => { setFilter(true) }} />
                                                <label for="current-result">Current filter result</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="radio" id="creators" name="export" value="All creators" defaultChecked onChange={() => { setFilter(false) }} />
                                                <label for="creators">All creators</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="field-wrapper">
                                        <span>EXPORT AS</span>
                                        <div className="radio-btns">
                                            <div className="form-group">
                                                <input type="radio" id="csv" name="export_as" value="CSV" defaultChecked onChange={() => setFileType("csv")} />
                                                <label for="csv">CSV</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="radio" id="PDF" name="export_as" value="PDF" onChange={() => setFileType("pdf")} />
                                                <label for="PDF">PDF</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-end">
                                <a href="#" className="btn-style transparent auto_width" >Cancel</a>
                                <button className="btn-style auto_width" onClick={handleExportedData} disabled={loadings.getExportedDataAsyncThunk}>
                                    {loadings.getExportedDataAsyncThunk ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        "Export"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
// exportDataModalClose