import React, { memo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { toTitleCase } from "../../utils/methods";
import { getId } from "../../utils/utils";
import eyeIcon from "../../assets/images/icons/eye-icon.svg";
import avatarIcon from "../../assets/images/icons/avatar-icon.svg";
import { dateFormat } from "../../utils/format";

const CreatorCardComponentDashboard = memo(({ item = {} }) => {
  return (
    <tr>
      <td style={{paddingLeft : 0}}>
        <div className="main-wrapper small-img">
          <div className="img-wrapper">
            <Link to={`/dashboard/creator-detail/${getId(item)}`}>
              <img
                src={
                  item?.avatar ? parseImagePathToURL(item?.avatar) : avatarIcon
                }
                alt="avatarIcon"
                className="rounded"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <strong>
              <Link to={`/dashboard/creator-detail/${getId(item)}`}>
                {item?.name || item?.userName}
              </Link>
            </strong>
            <span>{item?.email}</span>
          </div>
        </div>
      </td>
      <td>
        <p className="dark_text">{dateFormat(item?.createdAt)}</p>
      </td>
      <td>
        <p className={`status-text ${item.status == 'pending' ? 'pending' : item.status == 'active' ? 'active' : 'pending'}`}>{toTitleCase(item?.status)}</p>
      </td>
      <td style={{ textAlign: "end", paddingRight: 0 }}>
        <div className="action-btn-wrapper">
          <Link to={`/dashboard/creator-detail/${getId(item)}`}>
            <div className="icon-wrapper rounded medium">
              <img src={eyeIcon} alt="eyeIcon" />
            </div>
          </Link>
        </div>
      </td>
    </tr>
  );
});

export default CreatorCardComponentDashboard;
