import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function TrendLineChart ({}) {

    const data = {
        labels: ['1', '50', '3', '10', '5', '6', '7'],
        datasets: [
          {
            label: 'Trends Data',
            data: ['0', '60', '10', '40', '100', '150', '34'],
            fill: false,
            borderColor: '#578BFC',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      };

      const options = {
        maintainAspectRatio: true,
        aspectRatio: 0,
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
        },
        scales: {
            x: {
              ticks: {
                color: '#86909C', 
              },
              grid: {
                display: false, 
                borderDash: [5, 5],
              },
            },
            y: {
              ticks: {
                color: '#86909C',
              },
              grid: {
                color: '#E5E6EB', 
                borderDash: [5, 5], 
                borderDashOffset: 0,
              },
            },
          },
      };

    return(
        <div className="trend-line-chart-wrapper">
            <Line data={data} options={options}  style={{height:250}}/>
        </div>
    )
}