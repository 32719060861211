import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { useEffect } from "react";
// pages
import Dashboard from "./pages/Dashboard";
import JobsPage from "./pages/JobsPage";
import JobDetailPage from "./pages/JobDetailPage";
import TrackProjectPage from "./pages/TrackProjectPage";
import CreatorsPage from "./pages/CreatorsPage";
import CreatorDetailPage from "./pages/CreatorDetailPage";
import BrandsPage from "./pages/BrandsPage";
import BrandDetailPage from "./pages/BrandDetailPage";
import Settings from "./pages/Settings";
import SignIn from "./pages/SignIn";
// utils
import UnAuthenticated from "./layouts/unAuthenticated";
import Authenticated from "./layouts/authenticated";
// redux
import { useDispatch, useSelector } from "react-redux";
import { authenticateAsyncThunk } from "./redux/pagesSlices/authSlice";
// components
import LoaderComponent from "./components/LoaderComponent";
import DashboardHome from "./pages/DashboardHome";
import Compaignpage from "./pages/Compaignpage";

function App() {
  const d = useDispatch();
  const auth = useSelector((s) => s.auth);
  const loading = auth?.loadings?.authenticateAsyncThunk;
  // const user = auth?.user;

  useEffect(() => {
    d(authenticateAsyncThunk());
  }, [d]);

  if (loading) return <LoaderComponent />;
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<UnAuthenticated Component={SignIn} />} />
          <Route path="/sign-in" element={<UnAuthenticated Component={SignIn} />} />
          <Route path="/dashboard" element={<Authenticated Component={Dashboard} />} >
            <Route index path="" element={<DashboardHome />} />
            <Route path="jobs" element={<JobsPage />} />
            <Route path="job-detail/:jobId" element={<JobDetailPage />} />
            <Route path="creators" element={<CreatorsPage />} />
            <Route path="brands" element={<BrandsPage />} />
            <Route path="creator-detail/:creatorId" element={<CreatorDetailPage />} />
            <Route path="brand-detail/:id" element={<BrandDetailPage />} />
            <Route path="track-project" element={<TrackProjectPage />} />
            <Route path="setting" element={<Settings />} />
            <Route path="campaign" element={<Compaignpage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
