import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { toast } from "react-toastify";
import { controllers } from "chart.js";
// import { useNavigate } from "react-router-dom";
// Start Admin Slices
///////////////////////////////////////////////////

export const getAdminAnalyticsAsyncThunk = createAsyncThunk(
  "admin/getAdminAnalyticsAsyncThunk",
  catchAsync(async ({ params }) => {
    const response = await ApiRequests.getAdminAnalytics(params);
    return response?.data;
  })
);

export const getAdminDashboardStatsAsyncThunk = createAsyncThunk(
  "admin/getAdminDashboardStatsAsyncThunk",
  catchAsync(async ({ params }) => {
    const response = await ApiRequests.getAdminGraphStats(params);
    return response?.data;
  })
);

export const getTopCreatorsAsyncThunk = createAsyncThunk(
  "admin/getTopCreatorsAsyncThunk",
  catchAsync(async ({ params }) => {
    const response = await ApiRequests.getTopCreators(params);
    return response?.data;
  })
);
export const getExportedDataAsyncThunk = createAsyncThunk(
  "admin/getExportedDataAsyncThunk",
  catchAsync(async ({ fileType, filter, callback }, { dispatch, getState }) => {
    let params = {};
    if (filter === true) {
      let state1 = getState()?.creator?.paramsForThunk?.getCreatorsAsyncThunk;
      if (state1?.name) params.name = state1.name;
      if (state1?.sortBy) params.sortBy = `name:${state1.sortBy}`;
      params.fileType = fileType
    }
    params.fileType = fileType
    const response = await ApiRequests.getExportedData(params, { responseType: "blob" })
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `sample.${fileType}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    if (callback) callback()
    return response.data;
  })
)

const initialState = {
  //news states
  story: null,
  assets: null,
  asset: null,
  subscriptions: null,
  analytics: null,
  dashboardGraph: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [],
  },
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  topCreators: {
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 0,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loadings = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAnalyticsAsyncThunk.fulfilled, (state, action) => {
        state.analytics = action.payload;
      })
      .addCase(getAdminDashboardStatsAsyncThunk.fulfilled, (state, action) => {
        state.dashboardGraph = action.payload;
      })
      .addCase(getTopCreatorsAsyncThunk.fulfilled, (state, action) => {
        state.topCreators = action.payload;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getAdminAnalyticsAsyncThunk,
            getAdminDashboardStatsAsyncThunk,
            getTopCreatorsAsyncThunk,
            getExportedDataAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default adminSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  adminSlice.actions;
