import React from "react";
import { FaPlay } from "react-icons/fa";
import videoMediaImg from "../assets/images/video-media-img.svg";

export default function CreatorPortfolioTab() {
    return (
        <div className="creator-portfolio-main-wrapper">
            <div className="section-title-with-cards">
                <strong className="semi_bold dark large">Some projects i’ve worked on</strong>
            </div>
            <div className="portfolio-listings-section">
                <div className="video-listing-main-wrapper">
                    <ul className="sample-videos-list">
                        <li >
                            <video
                                controls
                                width="600"
                            >
                                <source src={videoMediaImg} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div
                                className="icon-wrapper"
                            >
                                <FaPlay />
                            </div>
                        </li>
                        <li >
                            <video
                                controls
                                width="600"
                            >
                                <source src={videoMediaImg} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div
                                className="icon-wrapper"
                            >
                                <FaPlay />
                            </div>
                        </li>
                        <li >
                            <video
                                controls
                                width="600"
                            >
                                <source src={videoMediaImg} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div
                                className="icon-wrapper"
                            >
                                <FaPlay />
                            </div>
                        </li>
                        <li >
                            <video
                                controls
                                width="600"
                            >
                                <source src={videoMediaImg} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div
                                className="icon-wrapper"
                            >
                                <FaPlay />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div >
    )
}