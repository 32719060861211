import React, { useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { LuEyeOff } from "react-icons/lu";
import circularCrossIcon from "../assets/images/icons/circular-cross-icon.svg";

import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userLoginAsyncThunk } from "../redux/pagesSlices/authSlice";
import { IoEyeOutline } from "react-icons/io5";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Must contain at least one lowercase character")
    .matches(/[A-Z]/, "Must contain at least one uppercase character")
    .matches(/[0-9]/, "Must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Must contain at least one special character"
    ),
});

export default function SignIn() {
  const d = useDispatch();
  const loading = useSelector((s) => s.auth?.loadings?.userLoginAsyncThunk);
  const router = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [resetForm, setResetForm] = useState(false);
  const handleSubmit = async (values, { resetForm }) => {
    console.log("values", values);
    await d(
      userLoginAsyncThunk({
        email: values.email,
        password: values.password,
        router,
      })
    );
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="sign-in-page">
      <div className="inner-main-page">
        <div className="inner-page-content">
          <div className="login-page-content">
            <div className="title-wrapper text-center">
              <h2>👋 Welcome back</h2>
              <p>Log in with your email or google account</p>
            </div>

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              // onSubmit={(values, { setSubmitting }) => {
              //     console.log('Form submitted:', values);
              //     setSubmitting(false); // Stop submit button loading after submission
              // }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, errors, handleChange }) => (
                <Form className="form transparent">
                  <div className="field-wrapper">
                    <div className="form-group with-right-icon">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="text"
                        value={resetForm ? "" : values.email}
                        className="form-control"
                        placeholder="Enter your email address"
                        id="email"
                        name="email"
                      />
                      {values.email && (
                        <img
                          src={circularCrossIcon}
                          alt="circularCrossIcon"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleChange({
                              target: { name: "email", value: "" },
                            })
                          }
                        />
                      )}
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="email" />
                    </span>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group with-right-icon">
                      <label htmlFor="password">Password</label>
                      <Field
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        id="password"
                        name="password"
                      />
                      {showPassword ? (
                        <IoEyeOutline onClick={handleShowPassword} />
                      ) : (
                        <LuEyeOff onClick={handleShowPassword} />
                      )}
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="password" />
                    </span>
                  </div>
                  <div className="form-bottom-text">
                    <div className="custom-checkbox-wrapper">
                      <label className="checkbox-label">
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                        Stay signed in
                      </label>
                    </div>
                    <Link to="/forget-password" className="blue">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="form-footer">
                    {/* <button type="submit" disabled={isSubmitting} className='btn-style full'> Next  </button> */}
                    <Button
                      type="submit"
                      disabled={loading}
                      className="btn-style full"
                    >
                      {loading ? <Spinner size="sm" /> : "Next"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
