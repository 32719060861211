// ** Reducers Imports
import auth from "./pagesSlices/authSlice";
import user from "./pagesSlices/userSlice";
import brand from "./pagesSlices/brandSlice";
import creator from "./pagesSlices/creatorSlice";
import job from "./pagesSlices/jobSlice";
import application from "./pagesSlices/applicationSlice";
import error from "./errors/handleErrorsAndPayloads";
import chat  from "./pagesSlices/chatSlice";
import payment  from "./pagesSlices/paymentSlice";
import invite  from "./pagesSlices/invitationSlice";
import admin from "./pagesSlices/adminSlice";
import { combineReducers } from "@reduxjs/toolkit";
import campaign from "./pagesSlices/campaignSlice"

const rootReducer = combineReducers({
  user,
  creator,
  application,
  brand,
  job,
  error,
  auth,
  chat,
  payment,
  invite,
  admin,
  campaign
});

export default rootReducer;
