import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {MdOutlineKeyboardArrowLeft} from "react-icons/md";
import {BsThreeDots} from "react-icons/bs";

import suspendIcon from "../assets/images/icons/cancel.svg";
import activateIcon from "../assets/images/icons/tick.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import logotiktokIcon from "../assets/images/icons/logotiktok.svg";
import logofacebookIcon from "../assets/images/icons/logofacebook.svg";
import logoinstagramIcon from "../assets/images/icons/logoinstagram.svg";
import logoytIcon from "../assets/images/icons/logoyt.svg";
import bagIcon from "../assets/images/icons/bag-icon-fill.svg";
import userIcon from "../assets/images/icons/user-fill-icon.svg";
import imgBocIcon from "../assets/images/icons/img-box-icon.svg";
import chartIconIcon from "../assets/images/icons/chart-icon.svg";
import serviceIcon from "../assets/images/icons/service-icon.svg";
import gearIcon from "../assets/images/icons/settings-icon.svg";
import barsIcon from "../assets/images/icons/bars-icon.svg";
import JobsImg from "../assets/images/job-img-1.svg";
import CreatorProfileOverViewTab from "../components/CreatorProfileOverViewTab";
import CreatorUniquenessComponent from "../components/CreatorUniquenessComponent";
import CreatorPortfolioTab from "../components/CreatorPortfolioTab";
import CreatorAnalyticsTab from "../components/CreatorAnalyticsTab";
import DashboardTrendsChartComponent from "../components/DashboardTrendsChartComponent";
import TrendChartComponent from "../components/TrendChartComponent";
import CreatorProfileServiceOfferedTab from "../components/CreatorProfileServiceOfferedTab";
import PreferencesActivityLog from "../components/PreferencesActivityLog";
import {useDispatch, useSelector} from "react-redux";
import {
    getCreatorJobTasksAsyncThunk,
    getCreatorDetailAsyncThunk,
    getCreatorJobAnalyticsAsyncThunk,
    reloadCreatorStatsAsyncThunk,
    updateCreatorAsyncThunk,
} from "../redux/pagesSlices/creatorSlice";
import {parseImagePathToURL} from "../utils/globalFunctions";
import {toTitleCase} from "../utils/methods";
import {dateFormat} from "../utils/format";
import {getId, returnArray, setValueIfExist} from "../utils/utils";
import {timeZones} from "../utils/timeZonesList";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import CreatorApplicationCardComponent from "../components/creators/CreatorApplicationCardComponent";
import NoCreatorApplicationFoundComponent from "../components/creators/NoCreatorApplicationFoundComponent";
import {Dropdown, DropdownButton} from "react-bootstrap";

export default function CreatorDetailPage() {
    const [creatorProfileTab, setCreatorProfileTab] = useState("jobs");
    const [status, setStatus] = useState("pending");
    const {creatorId} = useParams();
    const dispatch = useDispatch();
    const {creatorDetails: creator, jobAnalytics, creatorJobTasks} = useSelector(
        (s) => s.creator
    );
    const userDetails = creator?.userDetails;

    const creatorJobsParams = {
        ...setValueIfExist({status, populate: "brandId,jobId"}),
    };

    useEffect(() => {
        if (creatorId) dispatch(getCreatorDetailAsyncThunk(creatorId));
    }, [creatorId]);

    useEffect(() => {
        if (creatorId && creatorProfileTab == "jobs") {
            dispatch(getCreatorJobAnalyticsAsyncThunk(creatorId));
            dispatch(
                getCreatorJobTasksAsyncThunk({
                    id: creatorId,
                    params: creatorJobsParams,
                })
            );
        }
    }, [creatorId, creatorProfileTab, status]);

    const creatorProfileTabHandler = (tab) => {
        setCreatorProfileTab(tab);
    };

    const handleRefresh = () => {
        if (creatorId) {
            dispatch(getCreatorDetailAsyncThunk(creatorId));
            dispatch(getCreatorJobAnalyticsAsyncThunk(creatorId));
            dispatch(
                getCreatorJobTasksAsyncThunk({
                    id: creatorId,
                    params: {status, populate: "brandId,jobId"},
                })
            );
            dispatch(reloadCreatorStatsAsyncThunk({id: creatorId}));
        }
    };

    const handleChangeCreatorStatus = (status) => {
        dispatch(updateCreatorAsyncThunk({id: creatorId, data: {status}}));
    };
    const getSocialIcon = (type) => {
        switch (type) {
            case "Instagram":
                return logoinstagramIcon;
            case "YouTube":
                return logoytIcon;
            case "TikTok":
                return logotiktokIcon;
            case "Facebook":
                return logofacebookIcon;
            default:
                return null;
        }
    };

    return (
        <div className="creator-detail-page">
            <div className="inner-page-wrapper active m-0">
                <div className="container">
                    <div className="page-upper-section">
                        <div className="row">
                            <div className="col-md-4 align-self-center">
                                <div className="back-btn-wrapper">
                                    <Link to="/dashboard/creators">
                                        <MdOutlineKeyboardArrowLeft/>
                                        <strong className="medium-bold">Back to creators</strong>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-8 align-self-center">
                                <div className="btn-wrapper">
                                    {creator?.status == "active" ? (
                                        <button
                                            className="btn-style blue-border"
                                            onClick={() => handleChangeCreatorStatus("inactive")}
                                        >
                                            <img src={suspendIcon} alt="suspendIcon"/> Suspend creator
                                        </button>
                                    ) : (
                                        <button
                                            className="btn-style blue-border"
                                            onClick={() => handleChangeCreatorStatus("active")}
                                        >
                                            <img src={activateIcon} alt="activateIcon"/> Activate
                                            creator
                                        </button>
                                    )}
                                    <div className="icon-wrapper">
                                        <DropdownButton
                                            id={`dropdown-button-drop-down`}
                                            drop={"down"}
                                            variant="secondary"
                                            className="custom-dropdown three-dots-line"
                                            title={<BsThreeDots/>}
                                        >
                                            <Dropdown.Item onClick={handleRefresh}>
                                                Refresh
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="creator-profile-info-section">
                        <div className="basic-info-wrapper">
                            <div className="creator-name-info">
                                <div className="profile-img-wrapper ">
                                    <img
                                        src={
                                            creator?.avatar
                                                ? parseImagePathToURL(creator?.avatar)
                                                : avatarIcon
                                        }
                                        alt="avatarIcon"
                                        className="profile-img"
                                    />
                                </div>
                                <div className="text-wrapper">
                                    <strong className="blue">
                                        {creator?.name || creator?.userName}
                                    </strong>
                                </div>
                            </div>
                            <div className="performance-wrapper">
                                <div className="text-wrapper">
                                    <h3>$3k</h3>
                                    <span>GMV Per Video</span>
                                </div>
                                <div className="text-wrapper">
                                    <h3>$60k</h3>
                                    <span>GMV </span>
                                </div>
                            </div>
                            <div className="personal-info-wrapper">
                                <div className="text">
                                    <span>Location</span>
                                    <strong>{creator?.location}</strong>
                                </div>
                                <div className="text">
                                    <span>Age</span>
                                    <strong>{creator?.age}</strong>
                                </div>
                                <div className="text">
                                    <span>Gender</span>
                                    <strong>{toTitleCase(creator?.gender)}</strong>
                                </div>
                                <div className="text">
                                    <span>Member Since</span>
                                    <strong>
                                        {creator?.createdAt
                                            ? dateFormat(creator?.createdAt)
                                            : dateFormat(creator?.updatedAt)}
                                    </strong>
                                </div>
                            </div>
                            <div className="social-icons">
                                <ul className="social-icons-list">
                                    {returnArray(creator?.socials).map((social) => (
                                        <li key={getId(social)}>
                                            <Link
                                                to={social.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={getSocialIcon(social.type)}
                                                    alt={`${social.type} Icon`}
                                                />
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="creator-profile-main-content-section">
                            <div className="tabs-main-wrapper">
                                <ul className="tabs-wrapper">
                                    <li>
                                        <Link
                                            to="#"
                                            className={`${creatorProfileTab == "jobs" ? "active" : ""}`}
                                            onClick={() => creatorProfileTabHandler("jobs")}
                                        >
                                            <img src={bagIcon} alt="bagIcon"/> Jobs{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className={`${
                                                creatorProfileTab == "profileOverview" ? "active" : ""
                                            }`}
                                            onClick={() => creatorProfileTabHandler("profileOverview")}
                                        >
                                            <img src={userIcon} alt="bagIcon"/> Profile overview
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className={`${
                                                creatorProfileTab == "portfolio" ? "active" : ""
                                            }`}
                                            onClick={() => creatorProfileTabHandler("portfolio")}
                                        >
                                            <img src={imgBocIcon} alt="bagIcon"/> Portfolio{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className={`${
                                                creatorProfileTab == "analytics" ? "active" : ""
                                            }`}
                                            onClick={() => creatorProfileTabHandler("analytics")}
                                        >
                                            <img src={chartIconIcon} alt="bagIcon"/> Analytics &
                                            Insights
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className={`${
                                                creatorProfileTab == "services" ? "active" : ""
                                            }`}
                                            onClick={() => creatorProfileTabHandler("services")}
                                        >
                                            <img src={serviceIcon} alt="bagIcon"/> Services Offered
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className={`${
                                                creatorProfileTab == "preferences" ? "active" : ""
                                            }`}
                                            onClick={() => creatorProfileTabHandler("preferences")}
                                        >
                                            <img src={gearIcon} alt="bagIcon"/> Preference
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="inner-section ">
                                {creatorProfileTab === "jobs" && (
                                    <>
                                        <div className="creator-basic-info-cards-main-wrapper">
                                            <div className="row">
                                                <div className="col-md-3 mb-3 mb-md-0">
                                                    <div className="info-card-wrapper">
                                                        <div className="img-wrapper">
                                                            <img src={barsIcon} alt="barsIcon"/>
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p className="medium-bold">Total jobs</p>
                                                            <strong className="large dark">
                                                                {jobAnalytics?.totalJobs}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3 mb-md-0">
                                                    <div className="info-card-wrapper">
                                                        <div className="img-wrapper">
                                                            <img src={barsIcon} alt="barsIcon"/>
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p className="medium-bold">Completed</p>
                                                            <strong className="large dark">
                                                                {jobAnalytics?.completedJobs}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3 mb-md-0">
                                                    <div className="info-card-wrapper">
                                                        <div className="img-wrapper">
                                                            <img src={barsIcon} alt="barsIcon"/>
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p className="medium-bold">Failed</p>
                                                            <strong className="large dark">
                                                                {jobAnalytics?.unsuccessfulJobs}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3 mb-md-0">
                                                    <div className="info-card-wrapper">
                                                        <div className="img-wrapper">
                                                            <img src={barsIcon} alt="barsIcon"/>
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p className="medium-bold">Success rate</p>
                                                            <strong className="large dark">
                                                                {parseFloat(
                                                                    (jobAnalytics?.successRate ?? 0).toFixed(2)
                                                                )}{" "}
                                                                %
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="jobs-section shadowed-wrapper">
                                            <div className="section-title-with-cards">
                                                <strong className="semi_bold dark large">Jobs</strong>
                                                <div className="nav-tabs-main-wrapper">
                                                    <ul className="tabs-wrapper gray_bg">
                                                        <li className={status === "pending" && `active`}>
                                                            <a href="#" onClick={() => setStatus("pending")}>
                                                                Current
                                                            </a>
                                                        </li>
                                                        <li className={status === null && `active`}>
                                                            <a href="#" onClick={() => setStatus(null)}>
                                                                History
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="table-wrapper">
                                            {creatorJobTasks?.totalResults < 1 && (
                                                <table className="bottom-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <span className="white medium-bold">Name</span>
                                                        </th>
                                                        <th>
                                                            <span className="white medium-bold">Client</span>
                                                        </th>
                                                        <th>
                                                            <span className="white medium-bold">Status</span>
                                                        </th>
                                                        <th>
                                                            <span className="white medium-bold">Start</span>
                                                        </th>
                                                        <th>
                                                            <span className="white medium-bold">{status === "pending" ? "" : "End"}</span>
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                </table>
                                            )}
                                                    <ProviderErrorLoadingPagination
                                                        reducer="creator"
                                                        action="getCreatorJobTasksAsyncThunk"
                                                        asyncThunk={getCreatorJobTasksAsyncThunk}
                                                        dataKey="creatorJobTasks"
                                                        Component={CreatorApplicationCardComponent}
                                                        emptyMessage={
                                                            <>
                                                                <NoCreatorApplicationFoundComponent/>
                                                            </>
                                                        }
                                                        componentProps={{}}
                                                        pagination
                                                        Parent={({ children }) => (
                                                            <table className="bottom-bordered">
                                                              {creatorJobTasks?.totalResults > 0 && (
                                                                <thead>
                                                                 <tr>
                                                                    <th>
                                                                        <span className="white medium-bold">Name</span>
                                                                    </th>
                                                                    <th>
                                                                        <span className="white medium-bold">Client</span>
                                                                    </th>
                                                                    <th>
                                                                        <span className="white medium-bold">Status</span>
                                                                    </th>
                                                                    <th>
                                                                        <span className="white medium-bold">Start</span>
                                                                    </th>
                                                                    <th>
                                                                        <span className="white medium-bold">{status === "pending" ? "" : "End"}</span>
                                                                    </th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                              )}
                                                              <tbody>{children} </tbody>
                                                            </table>
                                                          )}
                                                    />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {creatorProfileTab === "profileOverview" && (
                                    <>
                                        <div className="profile-overview-section shadowed-wrapper">
                                            <CreatorProfileOverViewTab creator={creator}/>
                                        </div>
                                        <div className="creator-unique-wrapper shadowed-wrapper">
                                            <CreatorUniquenessComponent creator={creator}/>
                                        </div>
                                    </>
                                )}
                                {creatorProfileTab === "portfolio" && (
                                    <>
                                        <div className="creator-portfolio shadowed-wrapper">
                                            <CreatorPortfolioTab/>
                                        </div>
                                    </>
                                )}
                                {creatorProfileTab === "analytics" && (
                                    <div className="creator-analytics shadowed-wrapper">
                                        <CreatorAnalyticsTab/>
                                        <TrendChartComponent/>
                                    </div>
                                )}
                                {creatorProfileTab === "services" && (
                                    <>
                                        <div className="service-offered shadowed-wrapper">
                                            <CreatorProfileServiceOfferedTab creator={creator}/>
                                        </div>
                                    </>
                                )}
                                {creatorProfileTab === "preferences" && (
                                    <>
                                        <div className="preferences-content shadowed-wrapper">
                                            <div className="preferences-basic-info-wrapper">
                                                <div className="section-title-with-cards">
                                                    <strong className="semi_bold dark large">
                                                        Preference
                                                    </strong>
                                                </div>
                                                <div className="basic-information">
                                                    <div className="list-wrapper">
                                                        <p className="medium medium-bold">
                                                            Preferred Communication
                                                        </p>
                                                        <strong className="medium semi_bold dark">
                                                            Email, Sms
                                                        </strong>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <p className="medium medium-bold">
                                                            Push notification{" "}
                                                        </p>
                                                        <strong className="medium semi_bold dark">
                                                            {userDetails?.settings?.notifications
                                                                ? "Enabled"
                                                                : "Disabled"}
                                                        </strong>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        <p className="medium medium-bold">Time zone </p>
                                                        <strong className="medium semi_bold dark">
                                                            {
                                                                timeZones?.find(
                                                                    (tz) => tz.value === userDetails?.timeZone
                                                                )?.label
                                                            }
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activity-log-content shadowed-wrapper">
                                            <PreferencesActivityLog/>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
